import React, { ReactNode } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

const AuthLayout = ({
  children,
  rightLink,
}: {
  children: ReactNode;
  rightLink: any;
}) => {
  const Logo = require('../assets/images/Logo.png');

  return (
    <div className="bg-[#F9F9F9] w-full h-screen overflow-y-scroll">
      <div className="container mx-auto pt-[10px] px-2">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <Link to="/">
              <img src={Logo} alt="logo" className="h-[50px]" />
            </Link>
            {rightLink}
          </div>
          <div className="flex-1 overflow-y-scroll pb-20">
            <Fade>{children}</Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
