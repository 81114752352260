import React from "react";
import Input from "../../../../common/Input";
import Moment from "react-moment";
import { Divider } from "antd";

const SettingsTab = ({ me }: any) => {
  const user = me?.data?.user;
  const society = me?.data?.society;
  return (
    <div>
      <h3>Society Details</h3>
      <p className="mb-3">View or Update Society Details</p>

      <div>
        <img
          src={society?.profileImage}
          alt=""
          className="h-[100px] w-[100px] rounded-full mb-3"
        />
        <div className="flex gap-2">
          <div className="w-6/12">
            <Input
              label={"Society Name"}
              name={"societyName"}
              type={"text"}
              value={society?.societyName}
              disabled={true}
              placeholder={""}
            />
          </div>
          <div className="w-6/12">
            <Input
              label={"Interest Rate"}
              name={"interestRate"}
              type={"text"}
              value={society?.interestRate + "%"}
              disabled={true}
              placeholder={""}
            />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-6/12">
            <Input
              label={"Member Size"}
              name={"memberSize"}
              type={"text"}
              value={society?.memberSize}
              disabled={true}
              placeholder={""}
            />
          </div>
          <div className="w-6/12">
            <div className="mb-3">
              <label
                htmlFor=""
                className="text-[#14141480] flex justify-between items-center"
              >
                Date Joined
              </label>
              <div className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2">
                <Moment format="DD-MM-YYYY">{society?.createdAt}</Moment>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {/* <h3>User Details</h3> */}
      <div className="flex gap-2">
        <div className="w-6/12">
          <Input
            label={"Admin"}
            name={"name"}
            type={"text"}
            value={user?.firstName + " " + user?.lastName}
            disabled={true}
            placeholder={""}
          />
        </div>
        <div className="w-6/12">
          <Input
            label={"Email"}
            name={"interestRate"}
            type={"text"}
            value={user?.email}
            disabled={true}
            placeholder={""}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsTab;
