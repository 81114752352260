import { useMutation } from 'react-query';
import { apiConfig } from '../../../apiConfig';
import { message } from 'antd';
import { axiosInstance } from '../../../axiosInstance';

export const useResetPassword = () => {
  return useMutation(
    (email: string) =>
      axiosInstance
        .post(`${apiConfig.RESET_PASSWORD}`, { email: email })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error('failed');
      },
    }
  );
};
