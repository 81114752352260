import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, DatePicker, Divider, Table } from "antd";
import AppLayout from "../AppLayout";
import { useGetAccountSummary } from "../../../hooks/api/Accounts/useGetAccountSummary";
import Loader from "../../../common/Loader";
import { useGetEntriesQuery } from "../../../hooks/Entries/useGetEntriesQuery";
import Moment from "react-moment";
import NairaSign from "../../../common/NairaSign";
import moment from "moment";

const Account = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [timing, setTiming] = useState({ startDate: "", endDate: "" });
  const { data, isLoading } = useGetAccountSummary();
  const { data: entries, isLoading: isGettingEntries } = useGetEntriesQuery({
    search: search,
    page: page,
    tag: filter,
    isDeleted: isDeleted,
    startDate: timing?.startDate,
    endDate: timing?.endDate,
  });

  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (tag: string) => <span className="capitalize">{tag}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => (
        <span>
          <NairaSign />
          {amount.toLocaleString()}
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "bank",
      key: "details",
      render: (_: any, record: any) => (
        <div>
          <h3>{record.bank}</h3>
          <p>{record.accountNumber}</p>
        </div>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (_: any, record: any) => <div>{record.paymentMethod}</div>,
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (_: any, record: any) => (
        <div>
          <Moment format="DD-MM-YYYY">{record?.transactionDate}</Moment>
          <br />
          <Moment format="hh:mm A">{record?.transactionDate}</Moment>
        </div>
      ),
    },
    {
      title: "Date Recorded",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record: any) => (
        <div>
          <Moment format="DD-MM-YYYY">{record?.createdAt}</Moment>
          <br />
          <Moment format="hh:mm A">{record?.createdAt}</Moment>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "entryReceipt",
      key: "entryReceipt",
      render: (entryReceipt: any) => <button>View Receipt</button>,
    },
  ];

  const Assets = require("./assets/Assets.png");
  const Liabilities = require("./assets/Liabilities.png");
  const Others = require("./assets/Others.png");

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const paginationConfig = {
    total: entries?.data?.meta?.totalDocs || 0,
    current: entries?.data?.meta?.page || 1,
    pageSize: entries?.data?.meta?.limit || 10,
    onChange: handlePageChange,
  };

  return (
    <AppLayout title="Accounts">
      {isLoading && <Loader />}

      {!isLoading && data && (
        <div>
          <div className="bg-white p-4 rounded-md mb-4">
            <div className="flex gap-[20px] bg-white rounded-md p-2">
              <div className="w-4/12">
                <div className="flex items-center gap-2 mb-3">
                  <img
                    src={Assets}
                    alt=""
                    className="h-[40px] bg-ash p-2 rounded-md"
                  />
                  <h3>All Entries</h3>
                </div>
                <Divider className="my-0 pb-1" />
                <p className="text-[#1FAF38] text-[20px] font-bold">
                  <NairaSign />
                  {data?.data?.allEntries.toLocaleString()}
                </p>
              </div>
              <div className="w-4/12">
                <div className="flex items-center gap-2 mb-3">
                  <img
                    src={Liabilities}
                    alt=""
                    className="h-[40px] bg-ash p-2 rounded-md"
                  />
                  <h3>Liabilities</h3>
                </div>
                <Divider className="my-0 pb-1" />
                <p className="text-[#EB001B] text-[20px] font-bold">
                  <NairaSign />
                  {data?.data?.assets.toLocaleString()}
                </p>
              </div>
              <div className="w-4/12">
                <div className="flex items-center gap-2 mb-3">
                  <img
                    src={Others}
                    alt=""
                    className="h-[40px] bg-ash p-2 rounded-md"
                  />
                  <h3>Assets</h3>
                </div>
                <Divider className="my-0 pb-1" />
                <p className="text-[20px] font-bold">
                  <NairaSign />
                  {data?.data?.assets.toLocaleString()}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white p-4 rounded-md">
            <div className="flex justify-between items-center">
              <span>
                <h3 className="text-black text-[16px]">All Account Activity</h3>
                <p className="text-[14px]">View account history</p>
              </span>
              <Link
                to="/accounts/add"
                className="border border-green text-green px-[35px] py-[10px] rounded-[100px] hover:bg-green hover:text-white transition-ease-in duration-300"
              >
                Add Entry
              </Link>
            </div>
            <div className="flex justify-end py-4 gap-4">
              <div className="flex items-center gap-1">
                <Checkbox
                  value={isDeleted}
                  onChange={() => {
                    setIsDeleted(!isDeleted);
                  }}
                />
                <span>Is Deleted</span>
              </div>
              <input
                name="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
                className={
                  "p-[10px] rounded-md border-[1px] border-[#00000033]"
                }
              />
              <RangePicker
                showTime
                onCalendarChange={(dates) => {
                  if (dates && dates[0] && dates[1]) {
                    const startDate = dates[0].toISOString();
                    const endDate = dates[1].toISOString();
                    setTiming({ startDate, endDate });
                  }
                }}
                disabledDate={(current) => {
                  return current && current > moment().endOf("day");
                }}
              />
              <select
                className="p-3 rounded-md border-[1px] border-[#00000033]"
                name="tag"
                value={filter}
                onChange={(e) => {
                  setFilter(e?.target?.value);
                }}
              >
                <option value="">Filter</option>
                <option value="Savings">Savings</option>
                <option value="Special Savings">Special Savings</option>
                <option value="Monthly Dues">Monthly Dues</option>
                <option value="Development">Development</option>
                <option value="Other Savings">Other Savings</option>
                <option value="Withdrawal">Withdrawal</option>
                <option value="Shares">Shares</option>
              </select>
            </div>
            <Table
              rowKey="id"
              columns={columns}
              scroll={{ y: "70vh" }}
              dataSource={entries?.data?.docs}
              className="manrope mt-[10px] w-full"
              rowClassName="cursor-pointer"
              loading={isGettingEntries}
              pagination={paginationConfig}
              onRow={(record: any) => ({
                onClick: () =>
                  navigate(`/accounts/${record.id}/${record?.member}`),
              })}
            />
          </div>
        </div>
      )}
      {/* </div> */}
    </AppLayout>
  );
};

export default Account;
