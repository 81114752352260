import React, { useEffect, useState } from 'react';
import Loader from './Loader';

const WelcomeLoader = () => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const hasSeenLoader = localStorage.getItem('hasSeenWelcomeLoader');

    if (!hasSeenLoader) {
      setShowLoader(true);

      localStorage.setItem('hasSeenWelcomeLoader', 'true');

      setTimeout(() => {
        setShowLoader(false);
      }, 3000);
    }
  }, []);

  if (!showLoader) {
    return null;
  }

  return (
    <div className="absolute right-0 bg-white h-dvh w-full z-50 flex justify-center items-center">
      <Loader />
    </div>
  );
};

export default WelcomeLoader;
