/* eslint-disable react/display-name */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    const stringifyToken = localStorage.getItem('COTRACKR_USER_TOKEN');
    const parsedToken = stringifyToken ? JSON.parse(stringifyToken) : null;

    useEffect(() => {
      if (!parsedToken) {
        navigate('/');
      }
    }, [navigate, parsedToken]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
