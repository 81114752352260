import React, { useState } from 'react';

const Pricing = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  const togglePlan = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex justify-center mb-6 bg-water py-[10px] px-[18px] rounded-full">
        <button
          onClick={togglePlan}
          className={`py-2 px-4 rounded-full font-semibold transition-colors duration-300 ${
            isMonthly ? 'bg-green text-white' : 'bg-water text-black'
          }`}
        >
          Monthly
        </button>
        <button
          onClick={togglePlan}
          className={`py-2 px-4 rounded-full font-semibold transition-colors duration-300 ml-2 ${
            !isMonthly ? 'bg-green text-white' : 'bg-water text-black'
          }`}
        >
          Annually
        </button>
      </div>

      {isMonthly && (
        <div className="w-full mb-[40px] px-4">
          <div className="md:flex md:flex-row flex-col gap-[40px]">
            <div className="md:w-4/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              <p>Basic Bundle</p>
              <div className="flex">
                <b>NGN 5,200</b>/Per Month
              </div>
              <h3>For Small Scale Co-operatives or Societies.</h3>
              <i>1-500 Members</i>
            </div>
            <div className="md:w-4/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              <p>Premium Bundle</p>
              <div className="flex">
                <b>NGN 5,200</b>/Per Month
              </div>
              <h3>For Medium Scale Co-operatives or Societies.</h3>
              <i>501-1000 Members</i>
            </div>
            <div className="md:w-4/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              <p>Basic Bundle</p>
              <div className="flex">
                <b>NGN 5,200</b>/Per Month
              </div>
              <h3>For Large Scale Co-operatives or Societies.</h3>
              <i>Above 1000 Members</i>
            </div>
          </div>
        </div>
      )}
      {!isMonthly && (
        <div className="w-full mb-[40px] px-4">
          <div className="md:flex md:flex-row flex-col gap-[40px]">
            <div className="md:w-4/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              <p>Basic Bundle</p>
              <div className="flex">
                <b>NGN 5,200</b>/Per Year
              </div>
              <h3>For Small Scale Co-operatives or Societies.</h3>
            </div>
            <div className="md:w-4/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              <p>Premium Bundle</p>
              <div className="flex">
                <b>NGN 5,200</b>/Per Year
              </div>
              <h3>For Medium Scale Co-operatives or Societies.</h3>
            </div>
            <div className="md:w-4/12 w-full bg-white shadow-md p-[18px] rounded-md md:mb-0 mb-[10px]">
              <p>Basic Bundle</p>
              <div className="flex">
                <b>NGN 5,200</b>/Per Year
              </div>
              <h3>For Large Scale Co-operatives or Societies.</h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pricing;
