import { useMutation } from "react-query";
import { apiConfig } from "../../../apiConfig";
import { message } from "antd";
import { queryClient } from "../../../services/queryClient";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";
import { useGetMeQuery } from "../User/useGetMe";
import { unformatNumber } from "../../../utils/formatNumberWithCommas";

interface Props {
  loanAmount: string;
  interestRate: string;
  term: string;
  societyId: string;
  interestCalculationMethod: "Flat Interest" | "Reducing Balance";
  dateApplied: string;
}

export const useGenerateLoanQuote = (payload: Props) => {
  const token = getToken();

  const date = new Date();

  const updatedPayload = {
    ...payload,
    loanAmount: +unformatNumber(payload.loanAmount),
    interestRate: +payload?.interestRate,
    term: +payload?.term,
    dateApplied: date,
  };

  return useMutation(
    () =>
      axiosInstance
        .post("/loans/generate-quote", updatedPayload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    {
      onSuccess: (data: any) => {
        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
