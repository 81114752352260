import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Loader = () => {
  return (
    <div className="my-[100px]">
      <Spin
        className="text-green"
        size={'large'}
        indicator={<LoadingOutlined spin />}
      />
    </div>
  );
};

export default Loader;
