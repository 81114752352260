import React, { ChangeEvent, useState } from "react";
import AppLayout from "../AppLayout";
import { useGetAllLoansQuery } from "../../../hooks/api/Loans/useGetAllLoansQuery";
import { Drawer, Spin, Table } from "antd";
import { useGetMeQuery } from "../../../hooks/api/User/useGetMe";
import { LoadingOutlined } from "@ant-design/icons";
import { useGetMembersQuery } from "../../../hooks/api/Members/useGetMembersQuery";
import { useCreateLoanApplication } from "../../../hooks/api/Loans/useCreateLoanApplication";
import {
  formatNumberWithCommas,
  unformatNumber,
} from "../../../utils/formatNumberWithCommas";
import { Link, useNavigate } from "react-router-dom";
import NairaSign from "../../../common/NairaSign";

const LoanManagement = () => {
  const { data: loans, isLoading: isLoadingLoans } = useGetAllLoansQuery();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState<Loan | null>(null);
  const [collector, setCollector] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  const { data: me } = useGetMeQuery();
  const { data: members, isLoading: isLoadingMembers } = useGetMembersQuery({
    search,
  });

  const date = new Date();
  const navigate = useNavigate();

  const initialState = {
    loanAmount: "",
    interestRate: me?.data?.society?.interestRate,
    term: "",
    societyId: me?.data?.society?.id,
    guarantors: [],
    interestCalculationMethod: "",
    dateApplied: "",
  };

  const [payload, setPayload] = useState<{
    loanAmount: string;
    interestRate: number | undefined;
    term: string;
    societyId: string | undefined;
    guarantors: string[];
    interestCalculationMethod: string;
    dateApplied: string;
  }>(initialState);

  // const columns = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     key: "id",
  //     render: (id: string) => (
  //       <Link to={`/loan-management/${id}`} className="truncate">
  //         {id}
  //       </Link>
  //     ),
  //   },
  //   // {
  //   //   title: "Email",
  //   //   dataIndex: "email",
  //   //   key: "email",
  //   // },
  //   // {
  //   //   title: "First Name",
  //   //   dataIndex: "firstName",
  //   //   key: "firstName",
  //   // },
  //   // {
  //   //   title: "Last Name",
  //   //   dataIndex: "lastName",
  //   //   key: "lastName",
  //   // },
  //   // {
  //   //   title: "Date of Birth",
  //   //   dataIndex: "dateOfBirth",
  //   //   key: "dateOfBirth",
  //   //   render: (dateOfBirth: string) => {
  //   //     const date = new Date(dateOfBirth);
  //   //     const formattedDate = new Intl.DateTimeFormat("en-GB", {
  //   //       day: "2-digit",
  //   //       month: "2-digit",
  //   //       year: "numeric",
  //   //     }).format(date);
  //   //     return <span>{formattedDate}</span>;
  //   //   },
  //   // },
  //   // {
  //   //   title: "Phone Number",
  //   //   dataIndex: "phoneNumber",
  //   //   key: "phoneNumber",
  //   // },
  // ];

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const { mutateAsync, isLoading: isSubmittingApplication } =
    useCreateLoanApplication(collector?.id);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    mutateAsync(payload, {
      onSuccess: () => {
        setOpen(false);
        setPayload(initialState);
        setCollector({
          id: "",
          firstName: "",
          lastName: "",
          email: "",
        });
        setSearch("");
      },
    });
  };

  const loanColumns = [
    {
      title: "User ID",
      dataIndex: "user",
      key: "user",
      render: (text: string) => {
        return `${text.slice(0, 5)}...${text.slice(-4)}`;
      },
    },
    {
      title: "Name",
      dataIndex: "member",
      key: "name",
      render: (member: { firstName: string; lastName: string }) =>
        `${member.firstName} ${member.lastName}`,
    },
    {
      title: "Loan Status",
      dataIndex: "loanStatus",
      key: "loanStatus",
      render: (loanStatus: string) => (
        <span
          className={`capitalize font-bold 
            ${loanStatus === "paid" ? "text-[#1FAF38]" : "text-[#EB001B]"}`}
        >
          {loanStatus}
        </span>
      ),
    },
    {
      title: "Total Repayment",
      dataIndex: "repaymentSchedule",
      key: "totalRepayment",
      render: (repaymentSchedule: { totalRepayment: number }) => (
        <span>
          <NairaSign />
          {repaymentSchedule.totalRepayment.toLocaleString()}
        </span>
      ),
    },
  ];

  const handleRowClick = (loan: any) => {
    setSelectedLoan(loan);
    setVisible(true);
  };

  interface RepaymentSchedule {
    month: number;
    monthlyPrincipal: number;
    interest: number;
    total: number;
  }

  interface Loan {
    _id: string;
    user: string;
    member: {
      _id: string;
      email: string;
      firstName: string;
      lastName: string;
      savingBalance: number;
      specialSavingBalance: number;
      monthlyBalance: number;
      materialBuildingBalance: number;
      otherSavingsBalance: number;
      loanBalance: number;
      sharesBalance: number;
      dateOfBirth: string;
      phoneNumber: string;
      address: string;
      city: string;
      state: string;
      society: string;
      createdAt: string;
      updatedAt: string;
    };
    guarantors: Array<{
      _id: string;
      email: string;
      firstName: string;
      lastName: string;
      savingBalance: number;
      specialSavingBalance: number;
      monthlyBalance: number;
      materialBuildingBalance: number;
      otherSavingsBalance: number;
      loanBalance: number;
      sharesBalance: number;
      dateOfBirth: string;
      phoneNumber: string;
      address: string;
      city: string;
      state: string;
      society: string;
      createdAt: string;
      updatedAt: string;
    }>;
    society: string;
    loanStatus: string;
    term: number;
    interestRate: number;
    interestCalculationMethod: string;
    dateApplied: string;
    repaymentSchedule: {
      totalInterest: number;
      totalRepayment: number;
      schedule: RepaymentSchedule[];
      loanDisbursed: number;
    };
    createdAt: string;
    updatedAt: string;
    id: string;
  }

  type LoanList = Loan[];

  return (
    <AppLayout title="Loan Management">
      <div>
        <div className="flex items-center justify-between mb-[20px]">
          <div>
            <h3 className="text-black text-[16px]">All Loans</h3>
            <p className="text-[14px]">View Information on Member Loans</p>
          </div>
          <Link
            to="/loan-management/create"
            className="text-green rounded-full p-[10px] border-[1px] border-green text-[13px]"
          >
            Add Loan
          </Link>
        </div>
        {/* <div>
          {loans?.data?.docs &&
            loans.data.docs.map((loan: Loan) => {
              const truncatedUserId = `${loan.user.slice(
                0,
                5
              )}...${loan.user.slice(-4)}`;
              return (
                <div key={loan.id} className="flex">
                  <div className="w-3/12">{truncatedUserId}</div>{" "}
                  <div className="w-3/12">
                    {loan.member.firstName} {loan.member.lastName}
                  </div>
                  <div className="w-3/12">{loan.loanStatus}</div>
                  <div className="w-3/12">
                    {loan.repaymentSchedule.totalRepayment}
                  </div>
                </div>
              );
            })}
        </div> */}

        <Table
          rowKey="id"
          columns={loanColumns}
          loading={isLoadingLoans}
          className="cursor-pointer"
          dataSource={loans?.data?.docs}
          onRow={(record: any) => ({
            onClick: () => navigate(`/loan-management/${record.id}`),
          })}
        />
        <Drawer
          title="Loan Details"
          visible={visible}
          onClose={() => setVisible(false)}
          closable={false}
          maskClosable={false}
          width={500}
        >
          {selectedLoan && (
            <div className="px-6">
              <h3 className="mt-4">User ID: {selectedLoan.user}</h3>
              <p>
                <strong>Name:</strong> {selectedLoan.member.firstName}{" "}
                {selectedLoan.member.lastName}
              </p>
              <p>
                <strong>Email:</strong> {selectedLoan.member.email}
              </p>
              <p>
                <strong>Loan Status:</strong> {selectedLoan.loanStatus}
              </p>
              <p>
                <strong>Loan Amount:</strong>{" "}
                {selectedLoan.repaymentSchedule.loanDisbursed}
              </p>
              <p>
                <strong>Term:</strong> {selectedLoan.term} months
              </p>
              <p>
                <strong>Interest Rate:</strong> {selectedLoan.interestRate}%
              </p>
              <h4 className="mb-3 font-bold mt-4">Repayment Schedule:</h4>
              <div className="mb-[100px]">
                {selectedLoan.repaymentSchedule.schedule.map(
                  (schedule, index) => (
                    <div key={index} className="border mb-3 p-2 rounded-md">
                      <p className="font-bold text-green">
                        Month {schedule.month}
                      </p>
                      <p>Principal: {schedule.monthlyPrincipal.toFixed(2)}</p>
                      <p>Interest {schedule.interest.toFixed(2)}</p>
                      <p>Total {schedule.total.toFixed(2)}</p>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </Drawer>
      </div>
    </AppLayout>
  );
};

export default LoanManagement;

const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="size-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  );
};

const RemoveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="size-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 text-black"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    </svg>
  );
};
