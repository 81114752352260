import React from "react";

type Guarantor = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

const GuarantorsList = ({
  search,
  setSearch,
  collector,
  payload,
  members,
  setPayload,
  guarantorsList,
  setGuarantorsList,
}: {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  collector: { id: string } | null;
  payload: any;
  members: {
    data: {
      docs: Guarantor[];
    };
  };
  setPayload: React.Dispatch<React.SetStateAction<any>>;
  guarantorsList: Guarantor[];
  setGuarantorsList: any;
}) => {
  const handleAddGuarantor = (guarantor: Guarantor) => {
    setPayload((prev: any) => ({
      ...prev,
      guarantors: [...prev.guarantors, guarantor.id],
    }));

    setGuarantorsList((prev: any) => {
      const updatedGuarantors = [...prev, guarantor];

      if (updatedGuarantors.length === 2) {
        setSearch("");
      }

      return updatedGuarantors;
    });
  };

  const handleRemoveGuarantor = (id: string) => {
    setPayload((prev: any) => ({
      ...prev,
      guarantors: prev.guarantors.filter(
        (guarantorId: any) => guarantorId !== id
      ),
    }));
    setGuarantorsList((prev: any) =>
      prev.filter((guarantor: any) => guarantor.id !== id)
    );
  };

  return (
    <div>
      {search.length > 2 &&
        collector?.id &&
        payload?.loanAmount &&
        payload?.term &&
        guarantorsList.length < 2 && (
          <div className="mb-3">
            <div className="h-[150px] overflow-scroll">
              {members?.data?.docs?.length === 0 ? (
                <div className="py-[20px] text-center">No member found</div>
              ) : (
                members?.data?.docs?.map((guarantor) => {
                  const isGuarantor = payload.guarantors.includes(guarantor.id);

                  return (
                    <div
                      key={guarantor.id}
                      className="mb-3 border-b-[1px] pb-3 pr-3"
                    >
                      <div className="flex justify-between items-center">
                        <span>
                          <p className="font-bold text-[#131313c7]">
                            {guarantor.firstName} {guarantor.lastName}
                          </p>
                          <span className="text-[#14141480]">
                            {guarantor.email}
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            isGuarantor
                              ? handleRemoveGuarantor(guarantor.id)
                              : handleAddGuarantor(guarantor)
                          }
                          className={`px-2 py-2 bg-green-500 text-white rounded cursor-pointer ${
                            !isGuarantor ? "bg-[#1fb124]" : "bg-[#ce2626]"
                          }`}
                        >
                          {isGuarantor ? <RemoveIcon /> : <AddIcon />}
                        </span>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}

      {payload?.guarantors.length > 0 && (
        <div>
          <p className="text-[#14141480] mb-2">Select Guarantors</p>
          <div className="mb-3 flex gap-2">
            {guarantorsList.map(({ id, firstName, lastName }) => (
              <div
                key={id}
                className="bg-gray-200 flex gap-4 p-2 rounded-lg text-black mb-2 justify-between items-center"
              >
                <span>
                  {firstName} {lastName}
                </span>
                <button onClick={() => handleRemoveGuarantor(id)}>
                  <CloseIcon />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GuarantorsList;

const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="size-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  );
};

const RemoveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-5"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-5 text-gray-400"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    </svg>
  );
};
