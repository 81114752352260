import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { apiConfig } from '../../../apiConfig';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { axiosInstance } from '../../../axiosInstance';

interface Props {
  email: string;
  password: string;
}

interface SignInResponse {
  message: string;
  data: {
    token: string;
    societyName?: string;
    profileImage?: string;
  };
}

export const useSigninUser = () => {
  const navigate = useNavigate();

  return useMutation<SignInResponse, AxiosError, Props>(
    (payload: Props) =>
      axiosInstance
        .post<SignInResponse>(`${apiConfig.LOGIN}`, payload)
        .then((res: any) => res.data),
    {
      onSuccess: (data) => {
        const { token } = data.data;

        message.success(data.message);
        localStorage.setItem('COTRACKR_USER_TOKEN', JSON.stringify(token));
        navigate('/overview');
      },
      onError: (error: any) => {
        const errorMessage =
          error.response?.data?.message || 'Sign in failed. Please try again.';
        message.error(errorMessage);
      },
    }
  );
};
