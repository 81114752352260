import { ChangeEvent, FormEvent, useState } from "react";
import { useGetMembersQuery } from "../../../hooks/api/Members/useGetMembersQuery";
import { useAddMembers } from "../../../hooks/api/Members/useAddMembers";
import { useUpdateMemberProfile } from "../../../hooks/api/Members/useUpdateMemberProfile";
import { message } from "antd";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
};

export const useMemberHelper = () => {
  const [payload, setPayload] = useState(initialState);
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [updatePayload, setUpdatePayload] = useState({
    email: "",
    firstName: "",
    lastName: "",
    state: "",
    city: "",
    address: "",
    dateOfBirth: "",
    phoneNumber: "",
    nextOfKinFirstName: "",
    nextOfKinLastName: "",
    nextOfKinAddress: "",
  });
  const [open, setOpen] = useState({ status: false, title: "" });
  const [phone, setPhone] = useState("");
  const [search, setSearch] = useState("");
  const [states, setStates] = useState<string[]>([]);
  const [lgas, setLgas] = useState<string[]>([]);
  const { data, isFetching } = useGetMembersQuery({ search });

  const showDrawer = (title: "add" | "update") => {
    setOpen({ status: true, title });
  };

  const onCloseAddMember = () => {
    setPayload(initialState);
    setPhone("");
    setOpen({ status: false, title: "" });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleUpdateChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUpdatePayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getLGAsFromApi = async (state: string) => {
    if (!state) return;
    try {
      const response = await fetch(
        `https://nga-states-lga.onrender.com/?state=${state}`
      );
      if (!response.ok) throw new Error("Failed to fetch LGAs");
      const data = await response.json();
      setLgas(data);
    } catch (error) {
      console.error(error);
      setLgas([]);
      message.error("Failed to fetch LGAs");
    }
  };

  const handleStateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const state = e.target.value;
    setPayload({ ...payload, state, city: "" });
    getLGAsFromApi(state);
  };

  const handleLGAChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedLGA = e.target.value;
    setPayload({ ...payload, city: selectedLGA });
  };

  const { mutateAsync, isLoading: isSubmitting } = useAddMembers();
  const { mutateAsync: updateMemberDetails, isLoading: isUpdating } =
    useUpdateMemberProfile(selectedUser?.id);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutateAsync(
      { ...payload, phoneNumber: phone },
      {
        onSuccess: () => {
          onCloseAddMember();
        },
      }
    );
  };
  const handleUpdate = (e: FormEvent) => {
    e.preventDefault();
    updateMemberDetails(updatePayload, {
      onSuccess: () => {
        onCloseAddMember();
      },
    });
  };

  return {
    lgas,
    setLgas,
    isFetching,
    data,
    isSubmitting,
    payload,
    open,
    setOpen,
    phone,
    setPhone,
    states,
    setStates,
    selectedUser,
    setSelectedUser,
    updatePayload,
    setUpdatePayload,
    showDrawer,
    handleChange,
    handleLGAChange,
    handleStateChange,
    onCloseAddMember,
    handleUpdateChange,
    handleUpdate,
    handleSubmit,
  };
};
