import React from "react";
import AppLayout from "../AppLayout";
import { useParams, useNavigate } from "react-router-dom";
import { useGetEntriesQuery } from "../../../hooks/Entries/useGetEntriesQuery";
import Moment from "react-moment";
import { Table, Button } from "antd";

type Props = {};

const MemberSavings = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: entries, isFetching: isFetchingEntries } = useGetEntriesQuery({
    search: "",
    tag: "",
    isDeleted: false,
    startDate: "",
    endDate: "",
  });

  const goBack = () => {
    navigate(-1);
  };

  // Columns for Ant Design Table
  const columns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (text: string) => text || "N/A", // Handle empty values
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      render: (text: string) => text || "N/A", // Handle empty values
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => parseFloat(amount).toLocaleString() || "N/A", // Format amount
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
          <br />
          <Moment format=" hh:mm:ss a">{date}</Moment>
        </div>
      ),
    },
    {
      title: "Recorded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
          <br />
          <Moment format=" hh:mm:ss a">{date}</Moment>
        </div>
      ),
    },
  ];

  const data = entries?.data?.docs.map((entry: any) => ({
    key: entry.id,
    tag: entry.tag,
    bank: entry.bank,
    amount: entry.amount,
    paymentMethod: entry.paymentMethod,
    transactionDate: entry.transactionDate,
    createdAt: entry.createdAt,
  }));

  return (
    <AppLayout title="Member Savings">
      <div className="mb-3">
        <Button
          onClick={goBack}
          className="flex gap-1 items-center bg-gray-200 rounded-md p-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
            />
          </svg>
          Back
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        loading={isFetchingEntries}
        pagination={{ pageSize: 10 }}
        rowKey="id"
      />
    </AppLayout>
  );
};

export default MemberSavings;
