import React from "react";
import { Link, useParams } from "react-router-dom";
import AppLayout from "../AppLayout";
import Back from "../../../common/Back";
import { useGetLoanQuery } from "../../../hooks/api/Loans/useGetLoanQuery";
import Loader from "../../../common/Loader";

type Props = {};

const LoanDetails = (props: Props) => {
  const { id } = useParams();
  const { data, isFetching, isError } = useGetLoanQuery(`${id}`);

  if (isFetching) {
    return (
      <AppLayout>
        <div className="px-4 mt-5">
          <Back />
          <div className="mt-10 text-center">
            <Loader />
          </div>
        </div>
      </AppLayout>
    );
  }

  if (isError || !data) {
    return (
      <AppLayout>
        <div className="px-4 mt-5">
          <Back />
          <div className="mt-10 text-center">
            <p className="text-red-500">
              Failed to loan details. Please try again later.
            </p>
          </div>
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout title="Loan Details">
      <Back />
      <br />
      <div className="flex gap-4">
        <div className="w-8/12 p-3 bg-white rounded-md">
          <section className="mb-6">
            <div className="flex mb-4">
              <div className="w-6/12">
                <p className="text-gray-500">Total Interest</p>
                <h3 className="font-bold text-2xl">
                  ₦
                  {data?.data?.repaymentSchedule?.totalInterest.toLocaleString() ||
                    "N/A"}
                </h3>
              </div>
              <div className="w-6/12">
                <p className="text-gray-500">Total Repayment</p>
                <h3 className="font-bold text-2xl">
                  ₦
                  {data?.data?.repaymentSchedule?.totalRepayment.toLocaleString() ||
                    "N/A"}
                </h3>
              </div>
            </div>
            <hr />

            <h2 className="font-semibold text-[18px] text-gray-800 mb-2 mt-6">
              Information
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div>
                <p className="text-gray-500">User ID:</p>
                <Link
                  to={`/member-management/${data?.data?.member?.id}`}
                  className="font-medium text-green underline"
                >
                  {data.data.user || "N/A"}
                </Link>
              </div>
              <div>
                <p className="text-gray-500">Name:</p>
                <p className="font-medium">
                  {data?.data?.member.firstName +
                    " " +
                    data?.data?.member.lastName || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-gray-500">Email:</p>
                <p className="font-medium">
                  {data?.data?.member.email || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-gray-500">Loan Status:</p>
                <p className="font-medium capitalize">
                  {data.data.loanStatus || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-gray-500">Term:</p>
                <p className="font-medium">{data.data.term || "N/A"}</p>
              </div>
              <div>
                <p className="text-gray-500">Interest Rate:</p>
                <p className="font-medium">
                  {data.data.interestRate + "%" || "N/A"}
                </p>
              </div>
            </div>
          </section>
          <hr />
          <section className="mb-6 mt-3">
            <h2 className="font-semibold text-[18px] text-gray-800 mb-2">
              Guarantors
            </h2>
            <div>
              {data?.data?.guarantors.map(
                ({
                  id,
                  email,
                  firstName,
                  lastName,
                  phoneNumber,
                  savingBalance,
                }: any) => (
                  <div key={id}>
                    <Link
                      to={`/member-management/${id}`}
                      className="text-green  underline"
                    >
                      {firstName + " " + lastName}
                    </Link>
                    <div className="flex items-center gap-2 mb-3 mt-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                        />
                      </svg>
                      {email}
                    </div>
                    <div className="flex items-center gap-2 mb-3 mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>
                      {phoneNumber}
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
        </div>
        <div className="w-4/12">
          <section className="mb-6 p-3 bg-white rounded-md">
            <h2 className="font-semibold text-[18px] text-gray-800 mb-2">
              Breakdown
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {data?.data?.repaymentSchedule.schedule.map(
                ({ month, monthlyPrincipal, interest, total }: any) => (
                  <div key={month} className="mb-6">
                    <p className="font-bold text-green">Month {month}</p>
                    <p>
                      Principal: ₦
                      {Number(monthlyPrincipal.toFixed(2)).toLocaleString()}
                    </p>
                    <p className="text-gray-500">
                      Interest: ₦{Number(interest.toFixed(2)).toLocaleString()}
                    </p>
                    <p className="text-gray-500">
                      Total: ₦{Number(total.toFixed(2)).toLocaleString()}
                    </p>
                  </div>
                )
              )}
            </div>
          </section>
        </div>
      </div>
    </AppLayout>
  );
};

export default LoanDetails;

const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
      />
    </svg>
  );
};
const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
      />
    </svg>
  );
};
