export const getToken = (): string | null => {
  try {
    const actualToken = localStorage.getItem('COTRACKR_USER_TOKEN');
    if (actualToken) {
      return JSON.parse(actualToken);
    }
    return null; // Return null if no token is found
  } catch (error) {
    console.error('Failed to parse token:', error);
    return null; // Handle error and return null if parsing fails
  }
};
