import { useMutation } from 'react-query';
import { getToken } from '../../../utils/getToken';
import { message } from 'antd';
import { axiosInstance } from '../../../axiosInstance';

export const useUploadFile = () => {
  const token = getToken();

  return useMutation(
    async (imageData: string | Blob) => {
      if (!imageData) throw new Error('No file selected');

      const formData = new FormData();

      if (typeof imageData === 'string') {
        const blob = await (await fetch(imageData)).blob();
        formData.append('files', blob, 'image.png');
      } else {
        formData.append('files', imageData);
      }

      const response = await axiosInstance.post('misc/file-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    {
      onSuccess: (data) => {
        // let result = data?.data?.fileUrl;
        // setPayload({ ...payload, images: result });
      },
      onError: (error: any) => {
        console.error('❌ Upload failed', error);
        message.error(
          error?.response?.data?.message ||
            'Error uploading file, please try again.'
        );
      },
    }
  );
};
