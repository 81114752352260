import React from "react";
import AppLayout from "../AppLayout";
import Back from "../../../common/Back";
import { useParams } from "react-router-dom";
import { useGetEntryQuery } from "../../../hooks/Entries/useGetEntryQuery";

const AccountDetails = () => {
  const { accountId, memberId } = useParams<{
    accountId: string;
    memberId: string;
  }>();

  const { data, isLoading } = useGetEntryQuery({ accountId, memberId });
  const member = data?.data;

  return (
    <AppLayout title="Account Details">
      <Back />
      {isLoading && <div>Loading...</div>}
      {!isLoading && data && (
        <div className="p-6 bg-white shadow-md rounded-lg">
          {/* Transaction Details */}
          <h2 className="text-xl font-semibold mb-4">Transaction Details</h2>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <p className="text-gray-600">Tag:</p>
              <p className="font-medium">{member?.tag}</p>
            </div>
            <div>
              <p className="text-gray-600">Amount:</p>
              <p className="font-medium">₦{member?.amount.toLocaleString()}</p>
            </div>
            <div>
              <p className="text-gray-600">Payment Method:</p>
              <p className="font-medium">{member?.paymentMethod}</p>
            </div>
            <div>
              <p className="text-gray-600">Bank:</p>
              <p className="font-medium">{member?.bank}</p>
            </div>
            <div>
              <p className="text-gray-600">Transaction Date:</p>
              <p className="font-medium">
                {new Date(member?.transactionDate).toLocaleDateString()}
              </p>
            </div>
            <div>
              <p className="text-gray-600">Account Number:</p>
              <p className="font-medium">{member?.accountNumber}</p>
            </div>
            <div className="col-span-2">
              <p className="text-gray-600">Entry Receipt:</p>
              <img
                src={member?.entryReceipt}
                alt="Entry Receipt"
                className="w-full max-h-40 object-contain rounded"
              />
            </div>
          </div>

          {/* Member Details */}
          <h2 className="text-xl font-semibold mb-4">Member Details</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-600">Name:</p>
              <p className="font-medium">{`${member.firstName} ${member.lastName}`}</p>
            </div>
            <div>
              <p className="text-gray-600">Email:</p>
              <p className="font-medium">{member.email}</p>
            </div>
            <div>
              <p className="text-gray-600">Phone Number:</p>
              <p className="font-medium">{member.phoneNumber}</p>
            </div>
            <div>
              <p className="text-gray-600">Address:</p>
              <p className="font-medium">{member.address}</p>
            </div>
            <div>
              <p className="text-gray-600">City:</p>
              <p className="font-medium">{member.city}</p>
            </div>
            <div>
              <p className="text-gray-600">State:</p>
              <p className="font-medium">{member.state}</p>
            </div>
            {/* <div>
              <p className="text-gray-600">Monthly Balance:</p>
              <p className="font-medium">
                ₦{member.monthlyBalance.toLocaleString()}
              </p>
            </div>
            <div>
              <p className="text-gray-600">Loan Balance:</p>
              <p className="font-medium">
                ₦{member.loanBalance.toLocaleString()}
              </p>
            </div> */}
            <div>
              <p className="text-gray-600">Date of Birth:</p>
              <p className="font-medium">
                {new Date(member.dateOfBirth).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default AccountDetails;
