import { useMutation } from 'react-query';
import { axiosInstance } from '../../../axiosInstance';
import { apiConfig } from '../../../apiConfig';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

interface Props {
  email: string;
  otp: string;
  password: string;
}

export const useConfirmPassword = () => {
  const navigate = useNavigate();
  return useMutation(
    (payload: Props) =>
      axiosInstance
        .post(`${apiConfig.CONFIRM_PASSWORD}`, payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
        navigate('/login');
      },
      onError: (error: any) => {
        message.error('failed');
      },
    }
  );
};
