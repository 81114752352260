import React from "react";
import { Table } from "antd";
import dayjs from "dayjs";

type Billing = {
  id: number;
  tag: string;
  bank: string;
  amount: string;
  transactionDate: string;
};

const BillingsTab = ({ me }: any) => {
  const columns = [
    {
      title: "Billing Invoice",
      dataIndex: "tag",
      key: "tag",
      render: (text: string) => text || "N/A",
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      render: (text: string) => text || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => {
        // Parse the amount and remove non-numeric characters
        const numericAmount = parseFloat(amount.replace(/[^\d.-]/g, ""));
        return !isNaN(numericAmount) ? numericAmount.toLocaleString() : "N/A";
      },
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (date: string) => (
        <div>
          {/* Format date using moment.js or dayjs */}
          <div>{dayjs(date).format("DD-MM-YYYY")}</div>
          <div>{dayjs(date).format("hh:mm:ss a")}</div>
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: Billing) => <button>Download</button>,
    },
  ];

  const data: Billing[] = [
    {
      id: 1,
      tag: "Invoice - 002 Jan 2025",
      bank: "XYZ Bank",
      amount: "NGN 2,500",
      transactionDate: "2024-01-31T12:00:00Z",
    },
    {
      id: 2,
      tag: "Invoice - 003 Feb 2025",
      bank: "XYZ Bank",
      amount: "NGN 2,500",
      transactionDate: "2024-01-31T12:00:00Z",
    },
    {
      id: 3,
      tag: "Invoice - 004 Mar 2025",
      bank: "XYZ Bank",
      amount: "NGN 2,500",
      transactionDate: "2024-01-31T12:00:00Z",
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        className="cursor-pointer"
        dataSource={data}
      />
    </div>
  );
};

export default BillingsTab;
