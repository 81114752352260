import { useState } from "react";
import { Drawer, Empty, Table } from "antd";

import AppLayout from "../AppLayout";
import { useGetMemberQuery } from "../../../hooks/api/Members/useGetMembersQuery";
import "react-international-phone/style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../common/Loader";
import LoanApplication from "./MemberActions/LoanApplication";
import Withdrawal from "./MemberActions/Withdrawal";
import { useGetEntriesQuery } from "../../../hooks/Entries/useGetEntriesQuery";
import Moment from "react-moment";
import UserInfoLoader from "./Loaders/UserInfoLoader";
import Back from "../../../common/Back";
import MemberDeposits from "./MemberActions/MemberDeposits";
import Card from "../../../common/Card";

const MemberDetails = () => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const { data, isFetching, isError } = useGetMemberQuery({ id });
  const [open, setOpen] = useState({ title: "", status: false, subTitle: "" });
  const { data: entries, isFetching: isFetchingEntries } = useGetEntriesQuery({
    search: "",
    tag: "",
    isDeleted: false,
    startDate: "",
    endDate: "",
  });

  const navigate = useNavigate();

  const columns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (text: string) => text || "N/A",
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      render: (text: string) => text || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => parseFloat(amount).toLocaleString() || "N/A",
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
          <br />
          <Moment format=" hh:mm:ss a">{date}</Moment>
        </div>
      ),
    },
    {
      title: "Recorded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
          <br />
          <Moment format=" hh:mm:ss a">{date}</Moment>
        </div>
      ),
    },
  ];

  const entrydata = entries?.data?.docs.map((entry: any) => ({
    key: entry.id,
    tag: entry.tag,
    bank: entry.bank,
    amount: entry.amount,
    paymentMethod: entry.paymentMethod,
    transactionDate: entry.transactionDate,
    createdAt: entry.createdAt,
  }));

  if (isFetching) {
    return (
      <AppLayout>
        <div className="px-4 mt-5">
          <Back />
          <div className="mt-10 text-center">
            <Loader />
          </div>
        </div>
      </AppLayout>
    );
  }

  if (isError || !data) {
    return (
      <AppLayout>
        <div className="px-4 mt-5">
          <Back />
          <div className="mt-10 text-center">
            <p className="text-red-500">
              Failed to loan details. Please try again later.
            </p>
          </div>
        </div>
      </AppLayout>
    );
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const paginationConfig = {
    total: entries?.data?.meta?.totalDocs || 0,
    current: entries?.data?.meta?.page || 1,
    pageSize: entries?.data?.meta?.limit || 10,
    onChange: handlePageChange,
  };

  return (
    <AppLayout
      title="Member Management"
      crumb={`${data?.data?.firstName + " " + data?.data?.lastName}`}
    >
      <Back />
      <div className="flex gap-[20px]">
        <div className="w-8/12">
          <div className="flex items-center justify-between py-[10px] border-b-[1px] border-[#00000033]">
            <span className="">
              <h3 className="text-[16px] font-bold">Member Details</h3>
              <p className="text-[#000000CC] text-[12px]">
                View Member Account Information
              </p>
            </span>
            <div className="flex justify-between gap-2">
              <button
                onClick={() => {
                  setOpen({
                    title: "Deposit Fund",
                    status: true,
                    subTitle: "",
                  });
                }}
                className="border-[2px] px-[10px] text-white border-green rounded-full text-[10px] font-bold bg-green"
              >
                Deposit
              </button>
              <button
                onClick={() => {
                  setOpen({
                    title: "Withdraw",
                    status: true,
                    subTitle: "subtitle",
                  });
                }}
                className="border-[2px] px-[10px] text-white border-green rounded-full text-[10px] font-bold bg-green"
              >
                Withdraw
              </button>
              <button
                onClick={() => {
                  setOpen({
                    title: "Loan Application",
                    status: true,
                    subTitle: "subtitle",
                  });
                }}
                className="border-[2px] px-[10px] text-white border-green rounded-full text-[10px] font-bold bg-green"
              >
                Loan Application
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-1">
            <Card></Card>
          </div>
          <div>
            <div className="flex justify-between items-center my-3">
              <span>
                <h3>Activities</h3>
                <p>See all recent activities</p>
              </span>
              {entries && entries.data.docs.length > 5 && (
                <Link
                  to={`/member-management/savings/${id}`}
                  className="text-green underline"
                >
                  View Activity History
                </Link>
              )}
            </div>
            {!entries?.data?.docs || entries.data.docs.length === 0 ? (
              <div className="text-center py-[20px]">
                <Empty description="No entries available" />
              </div>
            ) : (
              <Table
                columns={columns}
                dataSource={entrydata}
                loading={isFetchingEntries}
                pagination={false}
                rowKey="id"
                onRow={(record: any) => ({
                  onClick: () => navigate(`/member-management/${record.id}`),
                })}
              />
            )}
          </div>
        </div>
        <div className="w-4/12">
          <h3 className="text-[16px] font-bold text-black mb-2">
            Member Details
          </h3>
          {isFetching ? (
            <UserInfoLoader />
          ) : (
            <div>
              <div className="flex items-center gap-2 mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>
                {data?.data?.address}
              </div>
              <div className="flex items-center gap-2 mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
                {data?.data?.phoneNumber}
              </div>
              <div className="flex items-center gap-2 mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                  />
                </svg>
                {data?.data?.email}
              </div>
              <div className="flex items-center gap-2 mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                  />
                </svg>
                <Moment interval={0} format="DD-MM-YYYY hh:mm a">
                  {data?.data?.dateOfBirth.toLocaleString()}
                </Moment>
              </div>
            </div>
          )}

          <hr />
          <h3 className="text-[16px] font-bold text-black mt-[10px] mb-2">
            Next of Kin Details
          </h3>
          {isFetching && <UserInfoLoader />}
        </div>
      </div>
      <Drawer
        width={500}
        open={open?.status}
        title={
          <div className="flex justify-between items-center">
            <span>
              <h3 className="text-[16px]">{open?.title}</h3>
              {/* <p className="text-[16px] font-normal">{open?.subTitle}</p> */}
            </span>
            <button
              onClick={() => {
                setOpen({ title: "", status: false, subTitle: "" });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-black"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        }
        closable={false}
      >
        <div className="p-[20px] text-black">
          {open?.title === "Deposit Fund" && (
            <MemberDeposits setOpen={setOpen} />
          )}
          {open?.title === "Loan Application" && <LoanApplication />}
          {open?.title === "Withdraw" && <Withdrawal setOpen={setOpen} />}
        </div>
      </Drawer>
    </AppLayout>
  );
};

export default MemberDetails;
