import { useMutation } from "react-query";
import { apiConfig } from "../../../apiConfig";
import { message } from "antd";
import { queryClient } from "../../../services/queryClient";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";
import { unformatNumber } from "../../../utils/formatNumberWithCommas";
import { useGetMeQuery } from "../User/useGetMe";
import { useNavigate } from "react-router-dom";

interface LoanPayload {
  loanAmount: string;
  interestRate: number;
  term: string;
  societyId: string;
  guarantors: string[];
  interestCalculationMethod: string;
  dateApplied: string | Date;
}

export const useCreateLoanApplication = (id: string) => {
  const token = getToken();
  const { data: me } = useGetMeQuery();
  const navigate = useNavigate();

  return useMutation(
    async (payload: any) => {
      if (!token || !me?.data?.society?.id) {
        throw new Error("Missing required data for loan application.");
      }

      const dateApplied = new Date();
      const formattedPayload = {
        ...payload,
        dateApplied,
        loanAmount: +unformatNumber(payload.loanAmount),
        interestRate: +me.data.society.interestRate,
        term: +payload.term,
        societyId: me.data.society.id,
      };

      // const { societyId } = formattedPayload;

      return axiosInstance
        .post(`/loans/${id}/apply`, formattedPayload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: (data: any) => {
        message.success(
          data?.message || "Loan application submitted successfully."
        );
        navigate(-1);
        queryClient.invalidateQueries("useGetLoan", {
          refetchActive: true,
        });
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message || "An error occurred.");
      },
    }
  );
};
