import { useMutation } from 'react-query';
import { apiConfig } from '../../../apiConfig';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../axiosInstance';

export const useVerifyOTP = () => {
  const navigate = useNavigate();
  return useMutation(
    (payload: { id: string; otp: string }) =>
      axiosInstance
        .post(`${apiConfig.VERIFY_OTP}`, payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
        const { token } = data.data;

        localStorage.setItem('COTRACKR_USER_TOKEN', JSON.stringify(token));
        navigate('/overview');
      },
      onError: (error: any) => {
        message.error('failed');
      },
    }
  );
};
