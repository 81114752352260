import React from "react";
import AppLayout from "../AppLayout";
import Back from "../../../common/Back";
import AddMemberForm from "./Forms/AddMemberForm";

type Props = {};

const AddMember = (props: Props) => {
  return (
    <AppLayout title="Create New Member">
      <Back />
      <AddMemberForm />
      {/* <form onSubmit={handleSubmit} className="p-[20px]">
        <div className="flex gap-3">
          <div className="w-6/12 mb-3">
            <label htmlFor="firstName" className="text-[#14141480]">
              First Name
            </label>
            <input
              name="firstName"
              type="text"
              className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
              placeholder="First Name"
              disabled={isSubmitting}
              value={payload?.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="w-6/12">
            <label htmlFor="lastName" className="text-[#14141480]">
              Last Name
            </label>
            <input
              name="lastName"
              type="text"
              className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
              placeholder="Last Name"
              disabled={isSubmitting}
              value={payload?.lastName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-6/12 mb-3">
            <label htmlFor="phoneNumber" className="text-[#14141480]">
              Phone Number
            </label>
            <PhoneInput
              defaultCountry="ng"
              name="phoneNumber"
              disabled={isSubmitting}
              className=" mt-2"
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </div>
          <div className="w-6/12">
            <label htmlFor="email" className="text-[#14141480]">
              Email Address
            </label>
            <input
              name="email"
              type="text"
              className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
              placeholder="Email Address"
              disabled={isSubmitting}
              value={payload?.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="dateOfBirth" className="text-[#14141480]">
            Date of Birth
          </label>
          <input
            name="dateOfBirth"
            type="date"
            className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
            placeholder="Select Date"
            disabled={isSubmitting}
            value={payload?.dateOfBirth}
            onChange={handleChange}
          />
        </div>
        <div className="flex gap-3">
          <div className="w-6/12">
            <label htmlFor="state" className="text-[#14141480]">
              State
            </label>
            <select
              name="state"
              disabled={isSubmitting}
              value={payload.state}
              onChange={handleStateChange}
              className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
            >
              <option value="">Select State</option>
              {states.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
          <div className="w-6/12">
            <label htmlFor="lga" className="text-[#14141480]">
              Local Government Area (LGA)
            </label>
            <select
              name="lga"
              disabled={isSubmitting || lgas.length === 0}
              value={payload.city}
              onChange={handleLGAChange}
              className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
            >
              <option value="">Select LGA</option>
              {lgas.map((lga, index) => (
                <option key={index} value={lga}>
                  {lga}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-[20px]">
          <label htmlFor="nextOfKinAddress" className="text-[#14141480]">
            Address
          </label>
          <textarea
            name="address"
            className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2 resize-none"
            placeholder="Enter Address"
            value={payload?.address}
            onChange={handleChange}
          />
        </div>
        <div className="mt-[20px] flex justify-end">
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-green text-white px-[35px] py-[10px] rounded-[100px]"
          >
            {isSubmitting ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              "Add Member"
            )}
          </button>
        </div>
      </form> */}
    </AppLayout>
  );
};

export default AddMember;
