import { Button, message, Upload, Spin } from "antd";
import React, { FormEvent, useState } from "react";
import { useUploadFile } from "../../../../hooks/api/Misc/useUploadFile";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { useAddDepositEntry } from "../../../../hooks/Entries/useAddDepositEntry";
import { useParams } from "react-router-dom";
import Card from "../../../../common/Card";

interface Payload {
  tag:
    | "Special Savings"
    | "Monthly Dues"
    | "Development"
    | "Other Savings"
    | "Withdrawal"
    | any;
  amount: string;
  paymentMethod: "Bank Transfer" | "Cheque" | "Cash";
  transactionDate: string;
  entryReceipt: string;
  bank: string;
  accountNumber: string;
}

const MemberDeposits = ({ setOpen }: { setOpen: any }) => {
  const [payload, setPayload] = useState<Payload>({
    tag: "Special Savings", // Default tag
    amount: "",
    paymentMethod: "Bank Transfer", // Default payment method
    transactionDate: "",
    entryReceipt: "",
    bank: "",
    accountNumber: "",
  });
  const { id } = useParams();

  const [activeTag, setActiveTag] = useState<string>("Savings (Default)");
  const activeButton = "p-[10px] rounded-md bg-green text-white flex-1";
  const inActiveButton =
    "p-[10px] rounded-md bg-white text-black border-[1px] border-[#00000033] flex-1 text-[14px]";

  const tags = [
    "Special Savings",
    "Monthly Dues",
    "Development",
    "Other Savings",
    "Withdrawal",
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date: string) => {
    const formattedDate = new Date(date).toISOString();
    setPayload((prev) => ({ ...prev, transactionDate: formattedDate }));
  };

  const formatNumberWithCommas = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/,/g, "");
    setPayload((prev) => ({ ...prev, [name]: numericValue }));
  };

  const {
    mutateAsync: uploadFile,
    isLoading: isUploading,
    data,
  } = useUploadFile();
  const { mutateAsync: addEntry, isLoading: isLoadingEntries } =
    useAddDepositEntry(id);

  const handleAddDeposit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addEntry(payload, {
      onSuccess: () => {
        message.success("Deposit entry added successfully!");
        setOpen({ title: "", status: false, subTitle: "" });
      },
      onError: (error) => {
        message.error(`Error adding deposit entry: ${error.message}`);
      },
    });
  };

  const handleFileChange = async (info: any) => {
    const { originFileObj } = info.file;
    if (originFileObj) {
      try {
        await uploadFile(originFileObj, {
          onSuccess: async (data) => {
            await setPayload({
              ...payload,
              entryReceipt: data?.data,
            });
          },
        });
      } catch (error) {
        message.error("Upload failed, please try again.");
      }
    }
  };

  return (
    <div>
      <h3 className="text-black">Details</h3>
      <p className="text-[#14141480]">Select Tag</p>
      <div className="flex gap-2 overflow-x-auto py-2">
        {tags.map((tag) => (
          <button
            key={tag}
            className={activeTag === tag ? activeButton : inActiveButton}
            onClick={() => {
              setActiveTag(tag);
              setPayload((prevPayload) => ({ ...prevPayload, tag }));
            }}
          >
            {tag}
          </button>
        ))}
      </div>
      <hr />
      <form onSubmit={handleAddDeposit}>
        <p className="text-[#14141480] my-2">Upload Receipt</p>
        {!data ? (
          <Upload
            onChange={handleFileChange}
            listType="picture"
            showUploadList={true}
          >
            <Button type="dashed" icon={<UploadOutlined />}>
              Upload Receipt
            </Button>
          </Upload>
        ) : (
          <img
            src={data?.data}
            className="h-[100px] w-[100px] rounded-full"
            alt="Uploaded receipt"
          />
        )}
        <div>
          <p className="text-[#14141480] my-2">Enter Amount</p>
          <input
            type="text"
            name="amount"
            className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mb-2"
            placeholder="Enter amount"
            value={formatNumberWithCommas(payload.amount)}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </div>
        <div className="flex gap-2 mb-2">
          <div className="w-6/12">
            <label className="text-[#14141480] mt-2" htmlFor="paymentMethod">
              Payment Method
            </label>
            <select
              id="paymentMethod"
              name="paymentMethod"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              onChange={handleChange}
              value={payload.paymentMethod}
            >
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="Cash">Cash</option>
              <option value="Cheque">Cheque</option>
            </select>
          </div>
          <div className="w-6/12">
            <label className="text-[#14141480] mt-2" htmlFor="transactionDate">
              Transaction Date
            </label>
            <input
              id="transactionDate"
              name="transactionDate"
              type="date"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              onChange={(e) => handleDateChange(e.target.value)}
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <div className="flex gap-2 mb-[20px]">
          <div className="w-6/12">
            <label className="text-[#14141480] mt-2" htmlFor="accountNumber">
              Account Number
            </label>
            <input
              id="accountNumber"
              name="accountNumber"
              type="text"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              placeholder="Enter account number"
              value={payload.accountNumber}
              onChange={handleChange}
            />
          </div>
          <div className="w-6/12">
            <label className="text-[#14141480] mt-2" htmlFor="bank">
              Bank
            </label>
            <input
              id="bank"
              name="bank"
              type="text"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              placeholder="Enter bank name"
              value={payload.bank}
              onChange={handleChange}
            />
          </div>
        </div>
        <button
          type="submit"
          disabled={
            !payload.accountNumber ||
            !payload.amount ||
            !payload.bank ||
            !payload.paymentMethod ||
            !payload.entryReceipt ||
            !payload.tag ||
            !payload.transactionDate
          }
          className={`p-3 font-bold text-white bg-green rounded-full w-full ${
            !payload.accountNumber ||
            !payload.amount ||
            !payload.bank ||
            !payload.paymentMethod ||
            !payload.entryReceipt ||
            !payload.tag ||
            !payload.transactionDate
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
        >
          {isLoadingEntries ? (
            <Spin
              className="text-white"
              size={"large"}
              indicator={<LoadingOutlined spin />}
            />
          ) : (
            "Submit"
          )}
        </button>
      </form>
      {/* {JSON.stringify(payload)} */}
    </div>
  );
};

export default MemberDeposits;
