import React, { ChangeEvent, useState } from "react";
import AppLayout from "../AppLayout";
import { useGetMembersQuery } from "../../../hooks/api/Members/useGetMembersQuery";
import { formatNumberWithCommas } from "../../../utils/formatNumberWithCommas";
import { useGetMeQuery } from "../../../hooks/api/User/useGetMe";
import { useCreateLoanApplication } from "../../../hooks/api/Loans/useCreateLoanApplication";
import { Drawer, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import GuarantorsList from "./components/GuarantorsList";
import Back from "../../../common/Back";
import LoanBreakdown from "./components/LoanBreakdown";
import LoanCollector from "./components/LoanCollector";
import Input from "../../../common/Input";

const CreateLoanApplication = () => {
  const [search, setSearch] = useState("");

  const [collector, setCollector] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const [guarantorsList, setGuarantorsList] = useState([]);

  const { data: me } = useGetMeQuery();

  const initialState = {
    loanAmount: "",
    term: "",
    guarantors: [],
    interestCalculationMethod: "",
    dateApplied: "",
    interestRate: me?.data?.society?.interestRate,
    societyId: me?.data?.society?.id,
  };

  const [payload, setPayload] = useState<{
    loanAmount: string;
    interestRate: number | undefined;
    term: string;
    societyId: string | undefined;
    guarantors: string[];
    interestCalculationMethod: string;
    dateApplied: string;
  }>(initialState);

  const { data: members, isLoading: isLoadingMembers } = useGetMembersQuery({
    search,
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({
      ...payload,
      [name]: value,
      interestRate: me?.data?.society?.interestRate,
      societyId: me?.data?.society?.id,
    });
  };

  const { mutateAsync, isLoading: isSubmittingApplication } =
    useCreateLoanApplication(collector?.id);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    mutateAsync(payload, {
      onSuccess: () => {
        setPayload(initialState);
        setCollector({
          id: "",
          firstName: "",
          lastName: "",
          email: "",
        });
        setSearch("");
      },
    });
  };

  const resetForm = () => {
    setPayload(initialState);
    setCollector({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
    });
    setSearch("");
  };

  return (
    <AppLayout title="Loan Management">
      <section className="mx-auto w-[500px] mt-4">
        <div className="bg-white p-3 rounded-lg">
          <div className="p-[20px]">
            <Back />
            <h3 className="text-xl mb-3">Create Loan Application</h3>
            <LoanCollector
              collector={collector}
              isLoadingMembers={isLoadingMembers}
              search={search}
              setSearch={setSearch}
              members={members}
              setCollector={setCollector}
              resetForm={resetForm}
            />
            <Input
              label={"Loan Amount"}
              name={"loanAmount"}
              type={"text"}
              value={formatNumberWithCommas(payload?.loanAmount)}
              placeholder={""}
              onChange={handleChange}
            />
            <div className="mb-3">
              <label htmlFor="term" className="text-[#14141480]">
                Term (No. of months for repayment)
              </label>
              <select
                onChange={handleChange}
                name="term"
                className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                value={payload?.term}
              >
                <option value="" disabled>
                  --Select Option--
                </option>
                <option value="6">6 months</option>
                <option value="12">12 months</option>
                <option value="15">15 months</option>
                <option value="18">18 months</option>
              </select>
            </div>
            {collector?.id &&
              payload?.loanAmount &&
              payload?.term &&
              payload?.guarantors.length < 2 && (
                <Input
                  label={"Select Guarantors"}
                  name={"term"}
                  type={"text"}
                  placeholder="Search"
                  onChange={(e) => setSearch(e?.target?.value)}
                  value={search}
                />
              )}
            {collector?.id &&
              payload?.loanAmount &&
              payload?.term &&
              isLoadingMembers && (
                <div className="pt-[20px] text-center">Fetching Members...</div>
              )}
            {
              <GuarantorsList
                search={search}
                setSearch={setSearch}
                collector={collector}
                payload={payload}
                members={members}
                setPayload={setPayload}
                guarantorsList={guarantorsList}
                setGuarantorsList={setGuarantorsList}
              />
            }

            <div className="mb-6">
              <label
                htmlFor="interestCalculationMethod"
                className="text-[#14141480]"
              >
                Calculation Method
              </label>
              <select
                onChange={handleChange}
                name="interestCalculationMethod"
                className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                value={payload?.interestCalculationMethod}
              >
                <option value="" disabled>
                  --Select Option--
                </option>
                <option value="reducing_balance">Reducing Balance</option>
                <option value="flat_interest">Flat Interest</option>
              </select>
            </div>

            <LoanBreakdown payload={payload} />
            <button
              type="submit"
              disabled={isSubmittingApplication}
              onClick={(e) => handleSubmit(e)}
              className="bg-green text-white w-full py-[10px] rounded-[100px]"
            >
              {isSubmittingApplication ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </section>
    </AppLayout>
  );
};

export default CreateLoanApplication;
