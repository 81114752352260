import React from "react";
import PageLayout from "../../common/PageLayout";

type SectionProps = {
  content: string;
};

type ListProps = {
  items: string[];
};

const Header = ({ content }: SectionProps) => {
  return <h3 className="text-green font-bold mt-6 mb-2">{content}</h3>;
};

const Body = ({ content }: SectionProps) => {
  return <p className="mb-4">{content}</p>;
};

const List = ({ items }: ListProps) => {
  return (
    <ul className="list-disc list-inside mb-4">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

const Terms = () => {
  return (
    <PageLayout>
      <div className="container mx-auto md:px-4 px-6 mb-[50px]">
        <div className="heading text-3xl text-green mt-6 font-bold">
          Terms of Use
        </div>

        <Header content="Introduction" />
        <Body content="These Terms of Use govern your access to and use of CoTrackr. By using the platform, you agree to comply with and be bound by these terms. If you do not agree to these terms, please refrain from using the platform." />

        <Header content="1. Acceptance of Terms" />
        <Body content="By creating an account, accessing, or using CoTrackr, you agree to these Terms of Use. CoTrackr reserves the right to modify these terms at any time. Continued use of the platform following any changes indicates your acceptance of the updated terms." />

        <Header content="2. User Responsibilities" />
        <Body content="As a user of CoTrackr, you agree to:" />
        <List
          items={[
            "Provide accurate, complete, and current information during registration and usage of the platform.",
            "Maintain the confidentiality of your login credentials and promptly notify CoTrackr of any unauthorized access to your account.",
            "Use the platform for lawful purposes only and in accordance with applicable laws and regulations.",
          ]}
        />

        <Header content="3. Intellectual Property" />
        <Body content="All content on the CoTrackr platform, including text, software, logos, graphics, and designs, is the intellectual property of CoTrackr or its licensors. Users are granted a limited, non-exclusive license to use the platform for its intended purpose. You may not modify, copy, distribute, or sell any of the platform’s content without explicit permission." />

        <Header content="4. Prohibited Conduct" />
        <Body content="You agree not to:" />
        <List
          items={[
            "Use the platform for any unlawful or fraudulent activity.",
            "Interfere with the operation of the platform or other users’ use of the platform.",
            "Attempt to gain unauthorized access to the platform’s systems or data.",
            "Introduce malicious software such as viruses, worms, or trojans.",
          ]}
        />

        <Header content="5. Service Availability" />
        <Body content="CoTrackr strives to provide uninterrupted service, but we do not guarantee that the platform will always be available or error-free. We may suspend or discontinue the platform at any time for maintenance, upgrades, or unforeseen circumstances." />

        <Header content="6. Limitation of Liability" />
        <Body content="To the fullest extent permitted by law, CoTrackr will not be liable for any indirect, incidental, or consequential damages arising from your use or inability to use the platform. This includes but is not limited to, loss of data, financial losses, or business interruptions." />

        <Header content="7. Termination" />
        <Body content="CoTrackr reserves the right to suspend or terminate your access to the platform if you violate these Terms of Use or engage in conduct deemed harmful to the platform or its users. Upon termination, your rights to use the platform will cease immediately." />

        <Header content="8. Governing Law" />
        <Body content="These Terms of Use are governed by and construed under the laws of the Federal Republic of Nigeria. Any disputes arising out of or related to these terms will be subject to the exclusive jurisdiction of the courts of the Federal Republic of Nigeria." />

        <Header content="9. Contact Us" />
        <Body content="If you have any questions or concerns about these Terms of Use, please contact us at admin@cotrackr.com." />
      </div>
    </PageLayout>
  );
};

export default Terms;
