import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {};

const Back = (props: Props) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="mb-3">
      <button
        onClick={goBack}
        className="flex gap-1 items-center bg-gray-200 rounded-md p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
          />
        </svg>
        Back
      </button>
    </div>
  );
};

export default Back;
