import { useQuery } from 'react-query';
import { apiConfig } from '../../../apiConfig';
import { message } from 'antd';
import { getToken } from '../../../utils/getToken';
import { axiosInstance } from '../../../axiosInstance';

export const useGetMeQuery = () => {
  const token = getToken();

  return useQuery({
    queryKey: ['useGetMeQuery'],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.GET_ME}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};
