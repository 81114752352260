import { Link } from "react-router-dom";
import Slider from "./Slider";
import Pricing from "./Pricing";
import PageLayout from "../../common/PageLayout";

export default function Home() {
  const EmailIcon = require("../../assets/images/Email.png");
  const TwitterIcon = require("../../assets/images/Twitter.png");
  const WhatsAppIcon = require("../../assets/images/WhatsApp.png");
  const SideImage = require("../../assets/images/sideimage.png");
  const Preview = require("../../assets/images/Preview.png");

  return (
    <PageLayout>
      <div className="bg-[#F9F9F9]">
        <section className="container mx-auto px-2 gap-[16px] md:flex block flex-row pt-[24px]">
          <div className="md:w-6/12 w-full bg-white rounded-md md:pl-[58px] pl-[20px] pr-[20px]">
            <div className="md:mt-[69px] mt-[10px] font-bold md:text-[30px] text-[24px] md:pt-0 pt-[40px]">
              Reliable Financial Solutions for
              <br className="md:block hidden" /> Thrift and Co-operative
              <br className="md:block hidden" /> Societies
              <p></p>
            </div>
            <p className="md:max-w-[70%] mb-[40px] mt-[20px]">
              CoTrackr provides reliable financial service for various Thrift
              and Co-operative Societies. We are pioneers in this service field,
              and the best among others.
            </p>
            <div className="flex md:flex-row flex-col md:gap-[16px] gap-[8px] md:mb-[120px] mb-[60px]">
              <Link
                to="/register"
                className="bg-green rounded-full px-[50px] py-[16px] text-white font-bold md:w-auto w-full text-center"
              >
                Get Started
              </Link>
              <button className="bg-ash px-[50px] py-[16px] rounded-full font-bold md:w-auto w-full md:mt-0 mt-[10px]">
                View Live Demo
              </button>
            </div>
            <aside>
              <h3 className="text-dark text-[32px] font-bold">20+</h3>
              <p className="text-black">
                Large Scale Co-operative Societies
                <br /> are using CoTrackr
              </p>
            </aside>
          </div>
          <Slider />
        </section>
      </div>
      <div className="bg-[#F9F9F9]">
        <section className="py-[80px] px-4">
          <p className="text-center text-green md:text-[24px] text-[16px]">
            What is CoTrackr?
          </p>
          <h3 className="font-bold text-black text-center md:text-[40px] text-[24px]">
            The Perfect tool to Supercharge Your
            <br className="md:block hidden" /> Cooperative Society !
          </h3>
          <div className="md:text-center md:max-w-[70%] md:mx-auto mt-[16px] md:text-[16px] text-[16px]">
            CoTrackr is an all-in-one management solution designed specifically
            for cooperatives, thrift, and credit societies. With its intuitive
            interface, CoTrackr simplifies the management of member accounts,
            savings, loans, and dividends. It helps societies stay organized,
            efficient, and transparent by automating many administrative tasks.
            Whether you&apos;re managing a small group or a large cooperative,
            CoTrackr is built to streamline your operations, ensuring secure,
            real-time access to your data.
          </div>
          <img src={Preview} alt="CoTrackr Preview" className="mx-auto" />
        </section>
      </div>
      <section id="features" className="container mx-auto my-[80px]">
        <p className="text-center text-green md:text-[24px] text-[16px]">
          What CoTrackr offers
        </p>
        <h3 className="font-bold text-black text-center md:text-[40px] text-[24px] mb-[40px]">
          Features & Capabilities
        </h3>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-[10px] px-4">
          <div className="px-[42px] py-[87px] bg-greenish rounded-md text-center text-white text-[20px] mt-[16px]">
            <div className="h-[120px] w-[120px] justify-center items-center rounded-full bg-white flex mx-auto">
              <MemberIcon />
            </div>
            <h3 className="font-bold text-[20px] mt-[16px]">
              Member Management
            </h3>
            <p className="md:text-[18px] text-[16px]">
              Easily manage member profiles, track contributions, savings, and
              loan applications, and ensure a seamless communication process
              with your members.
            </p>
          </div>

          <div className="px-[42px] py-[87px] bg-green rounded-md text-center text-white text-[20px] mt-[16px]">
            <div className="h-[120px] w-[120px] justify-center items-center rounded-full bg-white flex mx-auto">
              <LoanIcon />
            </div>
            <h3 className="font-bold text-[24px] mt-[16px]">Loan Management</h3>
            <p className="md:text-[18px] text-[16px]">
              Simplify loan application processes, approval workflows, and
              repayment tracking with automated calculations and reminders.
            </p>
          </div>

          <div className="px-[42px] py-[87px] bg-greenish rounded-md text-center text-white text-[20px] mt-[16px]">
            <div className="h-[120px] w-[120px] justify-center items-center rounded-full bg-white flex mx-auto">
              <AccountingIcon />
            </div>
            <h3 className="font-bold text-[24px] mt-[16px]">
              Accounting Report
            </h3>
            <p className="md:text-[18px] text-[16px]">
              Keep your financial records accurate and up-to-date with automated
              reports on savings, loans, shares, and dividends, all designed for
              easy auditing and transparency.
            </p>
          </div>

          <div className="px-[42px] py-[87px] bg-green rounded-md text-center text-white text-[20px] mt-[16px]">
            <div className="h-[120px] w-[120px] justify-center items-center rounded-full bg-white flex mx-auto">
              <AnalyticsIcon />
            </div>
            <h3 className="font-bold text-[24px] mt-[16px]">
              Analytics Dashboard
            </h3>
            <p className="md:text-[18px] text-[16px]">
              Gain valuable insights into your society’s performance with
              real-time analytics that highlight trends, financial health, and
              member activity, helping you make informed decisions.
            </p>
          </div>
        </div>
      </section>
      <section id="pricing" className="container mx-auto my-[80px]">
        <p className="text-center text-green md:text-[24px] text-[16px]">
          Our Subscription Plans.
        </p>
        <h3 className="font-bold text-black text-center md:text-[40px] text-[24px] mb-[40px]">
          Packages and Pricing
        </h3>
        {/* <span className="flex justify-center gap-[2px] bg-water px-[18px] py-[10px] mb-[40px]">
          <button className="text-white bg-green px-[28px] py-[8px] rounded-full font-bold">
            Monthly
          </button>
          <button className="bg-transparent text-dark px-[28px] py-[8px] rounded-full font-bold">
            Annually
          </button>
        </span> */}
        <Pricing />
        <div className="flex mx-auto w-[80%] text-[24px] text-center">
          Smartly manage your overall operation with any one of our packages
          that suits the unique needs of your cooperative society.
        </div>
        <div className="text-center mt-[24px]">
          <Link
            to="/register"
            className="bg-green rounded-full px-[50px] py-[16px] text-white font-bold md:w-auto w-full"
          >
            Get Started
          </Link>
        </div>
      </section>
      <section id="about" className="container mx-auto my-[80px] px-4">
        <div className="md:flex block">
          <div className="md:w-6/12 w-full md:pr-[40px]">
            <p className="mt-[93px] text-green text-[16px]">Who are we?</p>
            <h3 className="font-bold text-black md:text-[40px] text-[24px]">
              About Us
            </h3>
            <p className="mb-[10px] text-[16px] md:text-[23px] w-[11/12] text-dark">
              At CoTrackr, we are passionate about empowering cooperative,
              thrift, and credit societies with tools that enhance operational
              efficiency and financial transparency.{" "}
            </p>
            <p className="mb-[10px] text-[16px] md:text-[23px] w-[11/12] text-dark">
              Our team of developers, financial experts, and user experience
              designers has come together to create a system that addresses the
              unique needs of community-based financial organizations.
            </p>
            <p className="mb-[10px] text-[16px] md:text-[23px] w-[11/12] text-dark">
              We believe in the power of technology to transform traditional
              practices, making them more sustainable, secure, and accessible.
              With a deep commitment to innovation and customer service, we are
              here to support your society’s growth.
            </p>
          </div>
          <div className="md:w-6/12 md:block hidden h-[680px] rounded-md">
            <img src={SideImage} alt="" className="h-full" />
          </div>
        </div>
      </section>
      <section id="contact" className="container mx-auto my-[80px]">
        <p className="text-center text-green md:text-[24px] text-[16px]">
          We Would Love to Hear From You!
        </p>
        <h3 className="text-center font-bold md:text-[40px] text-[24px] mb-[40px]">
          Contact us
        </h3>
        <div className="md:flex block mx-auto justify-center gap-[10px] px-4">
          <div className="w-full md:w-[350px] rounded-[10px] p-[28px] bg-ash md:mb-0 mb-[10px]">
            <img src={EmailIcon} alt="email" className="mb-[28px] h-[48px]" />
            <h3 className="font-bold text-[24px]">Send Us a Mail</h3>
            <p className="text-[18px] mt-[16px] mb-[86px]">
              Reach us at <br />
              support@CoTrackr.com
            </p>
            <Link
              to="mailto:support@CoTrackr.com"
              target="_blank"
              className="text-green font-bold text-[18px]"
            >
              Email us
            </Link>
          </div>
          <div className="w-full md:w-[350px] rounded-[10px] p-[28px] bg-ash md:mb-0 mb-[10px]">
            <img
              src={WhatsAppIcon}
              alt="whatapp"
              className="mb-[28px] h-[48px]"
            />
            <h3 className="font-bold text-[24px]">Chat with us</h3>
            <p className="text-[18px] mt-[16px] mb-[86px]">
              Reach us through our WhatsApp <br />
              Channel
            </p>
            <Link
              to="https://web.whatsapp.com"
              target="_blank"
              className="text-green font-bold text-[18px]"
            >
              Message us
            </Link>
          </div>
          <div className="w-full md:w-[350px] rounded-[10px] p-[28px] bg-ash md:mb-0 mb-[10px]">
            <img
              src={TwitterIcon}
              alt="twitter"
              className="mb-[28px] h-[48px]"
            />
            <h3 className="font-bold text-[24px]">Tweet @CoTrackr</h3>
            <p className="text-[18px] mt-[16px] mb-[86px]">
              Send us a Tweet at <br />
              CoTrackrSupport
            </p>
            <Link
              to="https://www.x.com"
              target="_blank"
              className="text-green font-bold text-[18px]"
            >
              Tweet us
            </Link>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

const MemberIcon = () => {
  return (
    <svg
      width="45"
      height="48"
      viewBox="0 0 45 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 21C20.5109 21 18.6032 20.2098 17.1967 18.8033C15.7902 17.3968 15 15.4891 15 13.5C15 11.5109 15.7902 9.60322 17.1967 8.1967C18.6032 6.79018 20.5109 6 22.5 6C24.4891 6 26.3968 6.79018 27.8033 8.1967C29.2098 9.60322 30 11.5109 30 13.5C30 15.4891 29.2098 17.3968 27.8033 18.8033C26.3968 20.2098 24.4891 21 22.5 21ZM22.5 9C20.01 9 18 11.01 18 13.5C18 15.99 20.01 18 22.5 18C24.99 18 27 15.99 27 13.5C27 11.01 24.99 9 22.5 9Z"
        fill="#141414"
      />
      <path
        d="M40.5 33C39.66 33 39 32.34 39 31.5C39 30.66 39.66 30 40.5 30C41.34 30 42 29.34 42 28.5C42 26.5109 41.2098 24.6032 39.8033 23.1967C38.3968 21.7902 36.4891 21 34.5 21H31.5C30.66 21 30 20.34 30 19.5C30 18.66 30.66 18 31.5 18C33.99 18 36 15.99 36 13.5C36 11.01 33.99 9 31.5 9C30.66 9 30 8.34 30 7.5C30 6.66 30.66 6 31.5 6C33.4891 6 35.3968 6.79018 36.8033 8.1967C38.2098 9.60322 39 11.5109 39 13.5C39 15.36 38.34 17.04 37.2 18.36C41.67 19.56 45 23.64 45 28.5C45 30.99 42.99 33 40.5 33ZM4.5 33C2.01 33 0 30.99 0 28.5C0 23.64 3.3 19.56 7.8 18.36C6.69 17.04 6 15.36 6 13.5C6 11.5109 6.79018 9.60322 8.1967 8.1967C9.60322 6.79018 11.5109 6 13.5 6C14.34 6 15 6.66 15 7.5C15 8.34 14.34 9 13.5 9C11.01 9 9 11.01 9 13.5C9 15.99 11.01 18 13.5 18C14.34 18 15 18.66 15 19.5C15 20.34 14.34 21 13.5 21H10.5C8.51088 21 6.60322 21.7902 5.1967 23.1967C3.79018 24.6032 3 26.5109 3 28.5C3 29.34 3.66 30 4.5 30C5.34 30 6 30.66 6 31.5C6 32.34 5.34 33 4.5 33ZM31.5 42H13.5C11.01 42 9 39.99 9 37.5V34.5C9 28.71 13.71 24 19.5 24H25.5C31.29 24 36 28.71 36 34.5V37.5C36 39.99 33.99 42 31.5 42ZM19.5 27C17.5109 27 15.6032 27.7902 14.1967 29.1967C12.7902 30.6032 12 32.5109 12 34.5V37.5C12 38.34 12.66 39 13.5 39H31.5C32.34 39 33 38.34 33 37.5V34.5C33 32.5109 32.2098 30.6032 30.8033 29.1967C29.3968 27.7902 27.4891 27 25.5 27H19.5Z"
        fill="#141414"
      />
    </svg>
  );
};

const AccountingIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21H28.5V24H12V21ZM12 28.5H31.5V31.5H12V28.5Z"
        fill="#141414"
      />
      <path
        d="M42 6H6C5.20435 6 4.44129 6.31607 3.87868 6.87868C3.31607 7.44129 3 8.20435 3 9V39C3 39.7957 3.31607 40.5587 3.87868 41.1213C4.44129 41.6839 5.20435 42 6 42H42C42.7957 42 43.5587 41.6839 44.1213 41.1213C44.6839 40.5587 45 39.7957 45 39V9C45 8.20435 44.6839 7.44129 44.1213 6.87868C43.5587 6.31607 42.7957 6 42 6ZM42 9V12H6V9H42ZM6 39V15H42V39H6Z"
        fill="#141414"
      />
    </svg>
  );
};

const AnalyticsIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M45.55 16C45.8491 17.3123 46 18.654 46 20H28V2C32.0787 1.99998 36.0365 3.38521 39.225 5.92873C42.4135 8.47225 44.6436 12.0233 45.55 16ZM41.416 16C40.7517 13.7721 39.5431 11.7447 37.8992 10.1008C36.2553 8.45693 34.2279 7.24829 32 6.584V16H41.416Z"
        fill="#141414"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M2 28C1.99999 25.2963 2.60905 22.6274 3.78194 20.1913C4.95483 17.7553 6.66143 15.6148 8.775 13.9287C10.8886 12.2427 13.3548 11.0544 15.9906 10.4522C18.6263 9.84993 21.3639 9.84919 24 10.45V24H37.55C38.113 26.4703 38.1481 29.0315 37.6531 31.5163C37.1582 34.0011 36.1442 36.3533 34.6776 38.4193C33.211 40.4852 31.3248 42.2183 29.1423 43.5051C26.9599 44.7919 24.5303 45.6035 22.0126 45.8868C19.4949 46.1701 16.9458 45.9187 14.5319 45.149C12.1181 44.3794 9.89388 43.1088 8.00485 41.4204C6.11581 39.7321 4.60449 37.6639 3.56972 35.3513C2.53494 33.0386 2.00004 30.5336 2 28ZM33.608 28H20V14.392C17.3086 14.392 14.6776 15.1901 12.4398 16.6854C10.202 18.1806 8.4578 20.3059 7.42785 22.7925C6.39789 25.279 6.12841 28.0151 6.65347 30.6548C7.17854 33.2945 8.47458 35.7192 10.3777 37.6223C12.2808 39.5254 14.7055 40.8215 17.3452 41.3465C19.9849 41.8716 22.721 41.6021 25.2076 40.5722C27.6941 39.5422 29.8194 37.798 31.3146 35.5602C32.8099 33.3224 33.608 30.6914 33.608 28Z"
        fill="#141414"
      />
    </svg>
  );
};

const LoanIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 33L20.385 35.115L24.255 39H6V12H3V39C3 40.6545 4.3455 42 6 42H24.255L20.385 45.885L22.5 48L30 40.5L22.5 33Z"
        fill="#141414"
      />
      <path
        d="M16.5 25.5C17.3284 25.5 18 24.8284 18 24C18 23.1716 17.3284 22.5 16.5 22.5C15.6716 22.5 15 23.1716 15 24C15 24.8284 15.6716 25.5 16.5 25.5Z"
        fill="#141414"
      />
      <path
        d="M36 30H12C10.3455 30 9 28.6545 9 27V21C9 19.3455 10.3455 18 12 18H36C37.6545 18 39 19.3455 39 21V27C39 28.6545 37.6545 30 36 30ZM12 21V27H36V21H12Z"
        fill="#141414"
      />
      <path
        d="M42 6H23.745L27.615 2.115L25.5 0L18 7.5L25.5 15L27.615 12.885L23.745 9H42V36H45V9C45 7.347 43.6545 6 42 6Z"
        fill="#141414"
      />
    </svg>
  );
};
