import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
// import axiosInstance from '../../../axiosInstance';
import { apiConfig } from "../../../apiConfig";
import { message } from "antd";
import { queryClient } from "../../../services/queryClient";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";

interface Props {
  profileImage: string;
  interestRate: string;
  societyName: string;
  memberSize: string;
}

export const useUpdateUserPreference = (payload: Props) => {
  const navigate = useNavigate();
  const token = getToken();

  const updatedPayload = {
    ...payload,
    interestRate: +payload?.interestRate,
  };
  return useMutation(
    async () => {
      // Send PATCH request
      const response = await axiosInstance.patch(
        `${apiConfig.UPDATE_PROFILE}`,
        updatedPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    },
    {
      onSuccess: (data) => {
        message.success(data?.message);
        // Invalidate user-related queries to refresh data
        queryClient.invalidateQueries("useGetMeQuery");
        setTimeout(() => {
          navigate("/overview");
        }, 2000);
      },
      onError: (error: any) => {
        // const errorMessage =
        //   error.response?.data?.message ||
        //   'Error updating preferences. Please try again.';
        message.error(error?.message);
      },
    }
  );
};
