import { useMutation } from 'react-query';
import { apiConfig } from '../../../apiConfig';
import { message } from 'antd';
import { axiosInstance } from '../../../axiosInstance';

interface Props {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export const useRegisterUser = () => {
  return useMutation(
    (payload: Props) =>
      axiosInstance
        .post(`${apiConfig.REGISTER}`, payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(error?.message);
        // alert(`${error}`);
      },
    }
  );
};
