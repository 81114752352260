import { useMutation } from 'react-query';
import { message } from 'antd';
import { getToken } from '../../utils/getToken';
import { axiosInstance } from '../../axiosInstance';
import { apiConfig } from '../../apiConfig';
import { queryClient } from '../../services/queryClient';

// interface Props {
//     tag: "Special Savings" | "Monthly Dues" | "Development" | "Other Savings" | "Withdrawal",
//     amount: number,
//     paymentMethod: "Bank Transfer" | "Cheque" | "Cash",
//     entryReceipt: string
//     bank: string
//     accountNumber: string
// }

interface Props {
  tag: "Special Savings" | "Monthly Dues" | "Development" | "Other Savings" | "Withdrawal";
  amount: string;
  paymentMethod: "Bank Transfer" | "Cheque" | "Cash";
  transactionDate: string;
  entryReceipt: string;
  bank: string;
  accountNumber: string;
}



export const useAddDepositEntry = (id : any ) => {
    const token = getToken();

    return useMutation(
        (payload: Props) =>
            axiosInstance
                .post(`${apiConfig.ENTRIES}/${id}/deposit`, {...payload, amount: +payload?.amount}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res: any) => res.data),
        {
            onSuccess: (data: any) => {
                queryClient.invalidateQueries('useGetEntriesQuery', {
                    refetchActive: true,
                });

                message.success(data?.message);
            },
            onError: (error: any) => {
                message.error(error?.response?.data?.message);
            },
        }
    );
};
