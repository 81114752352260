import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Drawer, Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useRegisterUser } from "../../hooks/api/Auth/useRegisterUser";
import AuthLayout from "../../common/AuthLayout";
import { useVerifyOTP } from "../../hooks/api/Auth/useVerifyOTP";

const Register = () => {
  const [visibility, setVisibility] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [step, setStep] = useState(0);
  const [payload, setPayload] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  });
  const [open, setOpen] = useState(false);

  const [verificationPayload, setVerificationPayload] = useState({
    id: "",
    otp: "",
  });

  const [otp, setOtp] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (otp.length === 6) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [otp]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const { isLoading, mutateAsync } = useRegisterUser();
  const { isLoading: verifying, mutateAsync: verifyUser } = useVerifyOTP();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutateAsync(payload, {
      onSuccess: (data) => {
        setStep(1);
        setVerificationPayload({ ...verificationPayload, id: data?.data?.id });
      },
    });
  };

  const [timeLeft, setTimeLeft] = useState(120); // Timer starts at 60 seconds
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId); // Clear the interval on component unmount
    } else {
      setCanResend(true); // Enable the resend button when the timer ends
    }
  }, [timeLeft]);

  const handleResendCode = () => {
    // Resend code logic here
    setTimeLeft(60); // Reset timer after resending the code
    setCanResend(false); // Disable resend button until timer runs out again
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (e: any) => {
    setAgreement(e.target.checked);
  };

  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(payload?.password);
  const hasNumber = /\d/.test(payload?.password);
  const hasLetter = /[a-zA-Z]/.test(payload?.password);

  type SectionProps = {
    content: string;
  };
  const Header = ({ content }: SectionProps) => {
    return <h3 className="text-green font-bold mt-6 mb-2">{content}</h3>;
  };

  const Body = ({ content }: SectionProps) => {
    return <p className="mb-4">{content}</p>;
  };

  return (
    <AuthLayout
      rightLink={
        <span>
          Already have an account?{" "}
          <Link to="/login" className="text-green font-bold">
            Login
          </Link>
        </span>
      }
    >
      {step === 0 ? (
        <form
          className="mx-auto max-w-[600px] bg-white md:px-[100px] px-[20px] py-[40px]"
          onSubmit={handleSubmit}
        >
          <p className="text-center font-bold mb-[46px] text-[24px]">
            Create Your Account
          </p>
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 absolute text-[#141414] left-[25px] top-[25px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>
            <input
              type="email"
              name="email"
              placeholder="Enter Email Address"
              autoCorrect="false"
              disabled={isLoading}
              onChange={handleChange}
              className="w-full rounded-full py-[24px] px-[55px] border-[1px] border-[#00000033] mb-[16px]"
            />
          </div>
          <div className="relative">
            <input
              type="text"
              name="firstName"
              placeholder="Enter First Name"
              autoCorrect="false"
              disabled={isLoading}
              onChange={handleChange}
              className="w-full rounded-full py-[24px] px-[55px] border-[1px] border-[#00000033] mb-[16px]"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 absolute text-[#141414] left-[25px] top-[25px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>
          </div>
          <div className="relative">
            <input
              type="text"
              name="lastName"
              placeholder="Enter Last Name"
              autoCorrect="false"
              disabled={isLoading}
              onChange={handleChange}
              className="w-full rounded-full py-[24px] px-[55px] border-[1px] border-[#00000033] mb-[16px]"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 absolute text-[#141414] left-[25px] top-[25px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>
          </div>
          <div className="relative">
            <input
              type={!visibility ? "password" : "text"}
              name="password"
              disabled={isLoading}
              placeholder="Enter Your Password"
              onChange={handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="w-full rounded-full py-[24px] px-[55px] border-[1px] border-[#00000033] mb-[16px]"
            />
            <KeyIcon />

            <span
              onClick={() => setVisibility(!visibility)}
              className="absolute top-[25px] right-[25px] cursor-pointer"
            >
              {!visibility ? <OpenEye /> : <CloseEye />}
            </span>
          </div>
          {isFocused && (
            <div className="mb-2 text-[14px] pl-[20px]">
              <p
                className={`flex items-center ${
                  hasSpecialChar ? "text-[#4CAF50]" : "text-red-500"
                }`}
              >
                {hasSpecialChar ? (
                  <span className="mr-2 text-[#4CAF50]">✓</span>
                ) : (
                  <span className="mr-2 text-red-500">✘</span>
                )}
                Password should contain a special character
              </p>
              <p
                className={`flex items-center ${
                  hasNumber ? "text-[#4CAF50]" : "text-red-500"
                }`}
              >
                {hasNumber ? (
                  <span className="mr-2 text-[#4CAF50]">✓</span>
                ) : (
                  <span className="mr-2 text-red-500">✘</span>
                )}
                Password should contain numbers
              </p>
              <p
                className={`flex items-center ${
                  hasLetter ? "text-[#4CAF50]" : "text-red-500"
                }`}
              >
                {hasLetter ? (
                  <span className="mr-2 text-[#4CAF50]">✓</span>
                ) : (
                  <span className="mr-2 text-red-500">✘</span>
                )}
                Password should contain letters
              </p>
            </div>
          )}
          <div className="">
            <div className="flex gap-1 items-center">
              <Checkbox checked={agreement} onChange={handleCheckboxChange}>
                I agree with the terms and conditions
              </Checkbox>
              <button
                type="button"
                onClick={showDrawer}
                className="underline text-green"
              >
                Read here
              </button>
            </div>
          </div>
          <Drawer
            title={
              <div className="flex items-center justify-between">
                <h3>Master Service Agreement (MSA)s</h3>
                <button onClick={onClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            }
            closeIcon={false}
            footer={
              <div className="flex gap-2 items-center">
                <Checkbox
                  checked={agreement}
                  onChange={(e: any) => {
                    setAgreement(e.target.checked);
                    onClose();
                  }}
                >
                  I agree with the terms and conditions
                </Checkbox>
              </div>
            }
            open={open}
          >
            <div className="p-4">
              <p>
                This Master Service Agreement (the 'Agreement') is entered into
                as of [Date], by and between CoTrackr (the 'Service Provider'),
                and [Client Name] (the 'Client'). The purpose of this Agreement
                is to establish the general terms and conditions under which the
                Service Provider will provide services to the Client.
              </p>
              <Header content="1. Scope of Services" />
              <Body content="The Service Provider agrees to perform the services (the 'Services') described in one or more statements of work ('SOW'), which shall be mutually agreed upon by the parties. Each SOW shall be incorporated by reference into this Agreement." />

              <Header content="2. Term and Termination" />
              <Body
                content={
                  "This Agreement shall commence on the effective date and shall continue until terminated by either party upon [Number] days written notice. Either party may terminate this Agreement immediately in the event of a material breach by the other party."
                }
              />

              <Header content="3. Payment Terms" />
              <Body content="The Client agrees to pay the Service Provider the fees specified in each applicable SOW. Payment shall be made in accordance with the terms outlined in the SOW, and any late payments may be subject to interest charges. All fees are exclusive of taxes." />

              <Header content="4. Confidentiality" />
              <Body content="Both parties agree to maintain the confidentiality of any proprietary or confidential information disclosed by the other party during the term of this Agreement. Confidential information shall not be disclosed to any third party without the prior consent of the other party sought and obtained by the disclosing party." />

              <Header content="5. Intellectual Property" />
              <Body content="Any intellectual property developed by the Service Provider in the course of providing the Services shall remain the sole and exclusive property of the Service Provider, unless otherwise specified in the applicable SOW." />

              <Header content="6. Warranties and Disclaimers" />
              <Body content="The Service Provider warrants that the Services will be performed in a professional and workmanlike manner. Except as expressly stated in this Agreement, the Service Provider makes no other warranties, express or implied, including any implied warranties of merchantability or fitness for a particular purpose." />

              <Header content="7. Limitation of Liability" />
              <Body content="In no event shall either party be liable to the other for any indirect, incidental, or consequential damages arising out of or related to this Agreement. The total liability of the Service Provider under this Agreement shall not exceed the total amount paid by the Client for the Services." />

              <Header content="8. Indemnification" />
              <Body content="The Client agrees to indemnify and hold harmless the Service Provider from any claims, liabilities, or damages arising from the Client's use of the Services, except to the extent caused by the gross negligence or willful misconduct of the Service Provider." />
              <Header content="9. Governing Law and Dispute Resolution" />
              <Body content="This Agreement shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria. Any dispute arising from this agreement or the interpretation of it shall be subjected to Alternative Dispute Resolution by a licensed ADR practitioner and in accordance with the Arbitration and Mediation Act 2023." />
              <Header content="10. Miscellaneous" />
              <Body content="This Agreement, together with any applicable SOWs, constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior agreements or understandings, whether written or oral. This Agreement may only be amended in writing signed by both parties." />
            </div>
          </Drawer>
          <div className="mt-3">
            <Link to="/reset" className="text-green font-bold">
              Forgot Password?
            </Link>
          </div>
          <button
            disabled={
              !payload.email || !payload.password || isLoading || !agreement
            }
            className={`bg-green rounded-full p-[24px] font-bold text-white w-full mb-[40px] mt-[16px] ${
              !payload.email || !payload.password || isLoading || !agreement
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            type="submit"
          >
            {isLoading ? (
              <Spin
                className="text-white"
                size={"default"}
                indicator={<LoadingOutlined spin />}
              />
            ) : (
              "Proceed"
            )}
          </button>{" "}
          <div className="text-center">
            By proceeding you agree to the{" "}
            <Link
              to="/privacy"
              target="_blank"
              className="text-green underline"
            >
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link to="/terms" target="_blank" className="text-green underline">
              Terms of Use
            </Link>
          </div>
        </form>
      ) : (
        <div>
          <form className="mx-auto w-[600px] bg-white px-[100px] py-[40px] shadow-md">
            <h3 className="text-center font-bold mb-4 text-[24px]">
              Verify Your Account
            </h3>
            <p className="text-center mb-[46px]">
              We have sent you a one time password. Please enter it into the
              field below.
            </p>

            <div className="flex justify-center">
              <Input.OTP
                mask="*"
                value={otp}
                disabled={verifying}
                onChange={(value) => {
                  setOtp(value);
                }}
                autoFocus
              />
            </div>
            <button
              className={`bg-green rounded-full p-[24px] font-bold text-white w-full mb-[40px] mt-[16px] ${
                disabled || verifying ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={disabled || verifying}
              onClick={(e) => {
                e.preventDefault();
                setVerificationPayload({ ...verificationPayload, otp: otp });
                verifyUser({ ...verificationPayload, otp: otp });
              }}
            >
              {verifying ? (
                <Spin
                  className="text-white"
                  size={"default"}
                  indicator={<LoadingOutlined spin />}
                />
              ) : (
                "Proceed"
              )}
            </button>
            <div className="mt-3 text-center">
              Didn't get a code?{" "}
              <span className="text-green font-medium text-[20px]">
                {formatTime(timeLeft)}
              </span>
              <div className="mx-auto text-center">
                <button
                  className={`text-green my-[16px] text-[20px] font-bold ${
                    !canResend ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleResendCode}
                  disabled={!canResend}
                >
                  Resend code
                </button>
                <div className="text-center">
                  By proceeding you agree to the{" "}
                  <Link
                    to="/privacy"
                    target="_blank"
                    className="text-green underline"
                  >
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link
                    to="/terms"
                    target="_blank"
                    className="text-green underline"
                  >
                    Terms of Use
                  </Link>
                </div>
              </div>
            </div>
          </form>{" "}
        </div>
      )}
    </AuthLayout>
  );
};

export default Register;

const OpenEye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  );
};

const CloseEye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  );
};
const KeyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6 absolute text-[#141414] left-[25px] top-[25px]"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
      />
    </svg>
  );
};
