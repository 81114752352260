export const apiConfig = {
  // auth
  REGISTER: '/auth/register',
  VERIFY_OTP: '/auth/verify',
  LOGIN: '/auth/login',
  RESEND_OTP: '/auth/resend',
  RESET_PASSWORD: '/auth/reset-password',
  CONFIRM_PASSWORD: '/auth/confirm-password',
  GET_ME: '/users/me',
  // PROFILE
  UPDATE_PROFILE: '/users/preferences',
  // MEMBERS
  MEMBERS: '/members',
  // ENTRIES
  ENTRIES: '/entries',
  // MISC - FILE UPLOAD
  FILE_UPLOAD: '/misc/file-upload',
};
