import React, { useState, useEffect } from 'react';

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const I1 = require('../../assets/images/i1.png');
  const I2 = require('../../assets/images/i2.png');
  const I3 = require('../../assets/images/i3.png');
  const I4 = require('../../assets/images/i4.png');
  const I5 = require('../../assets/images/i5.png');
  const i1 = require('../../assets/images/illustration1.png');
  const i2 = require('../../assets/images/illustration2.png');
  const i3 = require('../../assets/images/illustration3.png');
  const i4 = require('../../assets/images/illustration4.png');
  const i5 = require('../../assets/images/illustration5.png');

  const images = [I1, I2, I3, I4, I5];
  const illustrations = [i1, i2, i3, i4, i5];
  const titles = [
    'Track Payment',
    'Manage Members',
    'Calculate Dividends',
    'Loan Application',
    'Manage Ledger',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);

      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % 5);
        setIsFading(false);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="px-4 w-full md:w-6/12 h-[700px] rounded-[10px] relative overflow-hidden">
      <div
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ${
          isFading ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <img
          src={images[activeIndex]}
          alt="banner"
          className="rounded-[10px] h-full w-full object-cover"
        />
      </div>

      <div className="absolute bottom-[40px] left-[40px]">
        <div className="text-white font-bold text-[16px] mb-[16px] flex gap-[16px] flex-row items-center">
          <img
            src={illustrations[activeIndex]}
            alt="illustration"
            className="h-[48px] w-[48px]"
          />

          <span>{titles[activeIndex]}</span>
        </div>

        <div className="flex gap-[4px]">
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <span
                key={index}
                className={`h-[6px] rounded-full w-[36px] ${
                  activeIndex === index ? 'bg-green' : 'bg-white'
                }`}
              ></span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
