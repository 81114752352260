import React from "react";

const PricingBanner = () => {
  return (
    <section className="bg-green rounded-md flex justify-between items-center px-[32px] py-[25px] mb-3">
      <span>
        <h3 className="text-white font-bold">
          You are currently Subscribed to Basic Bundle
        </h3>
        <p className="text-white">
          For Small Scale Co-operatives or Societies.
        </p>
      </span>
      <span className="flex items-center gap-[20px]">
        <span className="text-white">27 Days left</span>
        <button className="bg-white py-[16px] px-[54px] text-green rounded-full">
          View Plans
        </button>
      </span>
    </section>
  );
};

export default PricingBanner;
