import { Link } from "react-router-dom";
import Card from "../../../common/Card";
import AppLayout from "../AppLayout";
import { useGetDashboardOverviewQuery } from "../../../hooks/api/Dashboard/useGetDashboardOverviewQuery";

const Overview = () => {
  const Icon = require("../../../assets/images/icon.png");
  const { data } = useGetDashboardOverviewQuery();
  return (
    <AppLayout title="Overview">
      <div className="flex gap-1">
        <span className="p-4 rounded-md shadow-sm bg-white w-4/12">1</span>
        <span className="p-4 rounded-md shadow-sm bg-white w-4/12">1</span>
        <span className="p-4 rounded-md shadow-sm bg-white w-4/12">1</span>
      </div>
      {/* <div>{JSON.stringify(data)}</div> */}
      {/* <div>
        <div className="md:flex md:flex-row flex-col gap-[15px]">
          <div className="md:w-5/12 w-full">
            <Card
              title={'Account'}
              subtitle="You can view your account details"
              right="NGN"
            >
              <div className="mt-[24px]">
                <h3 className="text-[32px] font-bold text-dark">
                  NGN 475,090.86
                </h3>
                <p>Available Balance</p>
              </div>
              <div className="flex justify-between mt-[32px]">
                <button className="border-[1px] bordr-green py-[13px] px-[32px] text-green border-green rounded-full">
                  Deposit
                </button>
                <button className="border-[1px] bordr-green py-[13px] px-[32px] text-green border-green rounded-full">
                  Withdraw
                </button>
                <button className="border-[1px] bordr-green py-[13px] px-[32px] text-green border-green rounded-full">
                  Loan
                </button>
              </div>
              <div className="mt-[40px] flex gap-[24px] items-center">
                <img src={Icon} alt="" className="h-[60px] w-[60px]" />
                <span>
                  <h3>Fiscal Year Total Deposit</h3>
                  <p className="font-bold text-[20px]">NGN 2,508,429.48</p>
                </span>
              </div>
            </Card>
          </div>
          <div className="md:w-7/12 w-full">
            <Card
              title={'Cash Flow'}
              subtitle="Analytics to give context to your finances"
              right={<Indicators />}
            />
          </div>
        </div>
      </div>
      <div className="flex md:flex-row flex-col gap-[15px] mt-[15px]">
        <div className="md:w-7/12 w-full">
          <div className="flex md:flex-row flex-col gap-[15px] mb-[15px]">
            <div className="md:w-4/12 w-full">
              <Card
                title="Deposit"
                right="Last 30 days"
                headerSize="text-[16px]"
              >
                <h3 className="text-[26px] font-bold text-dark mt-[24px]">
                  NGN 605,850.11
                </h3>
                <p className="text-[14px] mt-[4px]">
                  vs. NGN 830,450.39 last period
                </p>
              </Card>
            </div>
            <div className="md:w-4/12 w-full">
              <Card title="Loans" right="Last 30 days" headerSize="text-[16px]">
                <h3 className="text-[26px] font-bold text-dark mt-[24px]">
                  NGN 200,590.00
                </h3>
                <p className="text-[14px] mt-[4px]">
                  vs. NGN 110,300.00 last period
                </p>
              </Card>
            </div>
            <div className="md:w-4/12 w-full">
              <Card
                title="Shares"
                right="Last 30 days"
                headerSize="text-[16px]"
              >
                <h3 className="text-[26px] font-bold text-dark mt-[24px]">
                  NGN 50,680.93
                </h3>
                <p className="text-[14px] mt-[4px]">
                  vs. NGN 21,150.84 last period
                </p>
              </Card>
            </div>
          </div>
          <Card
            title="Activities"
            subtitle="See all  recent activities"
            right={
              <Link to="/" className="text-green underline">
                View All Activity
              </Link>
            }
          >
            <div className="mt-[24px]">
              {data.map(
                ({
                  id,
                  _id,
                  name,
                  elapsed,
                  time,
                  caption,
                  amount,
                }: {
                  id: number;
                  _id: string;
                  name: string;
                  elapsed: string;
                  time: string;
                  caption: string;
                  amount: string;
                }) => (
                  <div
                    key={id}
                    className="flex gap-2 items-center border-l-[2px] border-green mb-3 pl-2"
                  >
                    <div className="w-2/12">
                      <h3 className="font-medium">{time}</h3>
                      <p>{elapsed}</p>
                    </div>
                    <div className="flex gap-1 items-center w-4/12">
                      <img
                        src="https://placehold.co/600x400/png"
                        height={48}
                        width={48}
                        alt="avatar"
                        className="rounded-full h-[48px] w-[48px]"
                      />
                      <div>
                        <h3>{name}</h3>
                        <p>{_id}</p>
                      </div>
                    </div>
                    <span className="w-4/12">{caption}</span>
                    <span className="w-2/12">{amount}</span>
                  </div>
                )
              )}
            </div>
          </Card>
        </div>
        <div className="md:w-5/12 w-full">
          <Card title="Summary" subtitle="View Your Dashboard Summary">
            <p className="mb-[16px] mt-[24px]">Assets</p>
            <div className="flex rounded-full overflow-hidden">
              <span className="height-[20px] bg-green w-8/12">.</span>
              <span className="height-[20px] bg-[#1F0692] w-4/12">.</span>
            </div>
            <div className="mt-[32px] flex justify-between mb-[40px]">
              <span className="flex gap-[5px] items-center">
                <span className="h-[10px] w-[10px] rounded-full bg-[#1F0692]"></span>
                <span className="font-bold text-dark">Deposit</span>
              </span>
              <h3 className="font-bold">NGN 605,850.11</h3>
            </div>
            <div className="mt-[32px] flex justify-between mb-[40px]">
              <span className="flex gap-[5px] items-center">
                <span className="h-[10px] w-[10px] rounded-full bg-green"></span>
                <span className="font-bold text-dark">Deposit</span>
              </span>
              <h3 className="font-bold">NGN 50,680.93</h3>
            </div>
            <p className="mb-[16px]">Liabilities</p>
            <div className="flex rounded-full overflow-hidden">
              <span className="height-[20px] bg-greenish w-7/12">.</span>
              <span className="height-[20px] bg-[#CCF6F0] w-5/12">.</span>
            </div>
            <div className="mt-[32px] flex justify-between mb-[40px]">
              <span className="flex gap-[5px] items-center">
                <span className="h-[10px] w-[10px] rounded-full bg-[#CCF6F0]"></span>
                <span className="font-bold text-dark">Loans</span>
              </span>
              <h3 className="font-bold">NGN 605,850.11</h3>
            </div>
            <div className="mt-[32px] flex justify-between mb-[40px]">
              <span className="flex gap-[5px] items-center">
                <span className="h-[10px] w-[10px] rounded-full bg-greenish"></span>
                <span className="font-bold text-dark">Withdrawals</span>
              </span>
              <h3 className="font-bold">NGN 50,680.93</h3>
            </div>
          </Card>
        </div>
      </div> */}
    </AppLayout>
  );
};

export default Overview;

const Indicators = () => {
  return (
    <div className="flex bg-[#E8E8E833] rounded-[10px] px-[20px] py-[11px] text-[14px] gap-[16px]">
      <span className="flex gap-[5px] items-center">
        <span className="h-[10px] w-[10px] rounded-full bg-green"></span>
        <span className="font-bold text-dark">Deposit</span>
      </span>
      <span className="flex gap-[5px] items-center">
        <span className="h-[10px] w-[10px] rounded-full bg-greenish"></span>
        <span className="font-bold text-dark">Withdrawal</span>
      </span>
      <span className="flex gap-[5px] items-center">
        <span className="h-[10px] w-[10px] rounded-full bg-[#CCF6F0]"></span>
        <span className="font-bold text-dark">Loan</span>
      </span>
    </div>
  );
};

const data = [
  {
    id: 1,
    time: "11:19 AM",
    elapsed: "10 Min",
    name: "Qudus Abdullah",
    _id: "#TID2122210",
    caption: "Member Paid their monthly Dues",
    amount: "NGN 60.00",
  },
  {
    id: 2,
    time: "11:19 AM",
    elapsed: "10 Min",
    name: "Alliyah Ahmed Shola",
    _id: "#TID2122210",
    caption: "Member Was Granted a Loan",
    amount: "NGN 60.00",
  },
  {
    id: 3,
    time: "11:19 AM",
    elapsed: "10 Min",
    name: "Bamidele Afolayan",
    _id: "#TID2122210",
    caption: "Member Made a Withdrawal",
    amount: "NGN 60.00",
  },
];
