import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Drawer, Empty, message, Spin, Table } from "antd";

import AppLayout from "../AppLayout";
import { useGetMembersQuery } from "../../../hooks/api/Members/useGetMembersQuery";
import { LoadingOutlined } from "@ant-design/icons";
import { useAddMembers } from "../../../hooks/api/Members/useAddMembers";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import MemberSkeleton from "./MemberSkeleton";
import { useUpdateMemberProfile } from "../../../hooks/api/Members/useUpdateMemberProfile";
import { Link, useNavigate } from "react-router-dom";
import { useGetMeQuery } from "../../../hooks/api/User/useGetMe";
import Input from "../../../common/Input";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
};

const Members = () => {
  const { data: company } = useGetMeQuery();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [queryTag, setQueryTag] = useState("all");
  const [payload, setPayload] = useState(initialState);
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [updatePayload, setUpdatePayload] = useState({
    email: "",
    firstName: "",
    lastName: "",
    state: "",
    city: "",
    address: "",
    dateOfBirth: "",
    phoneNumber: "",
    nextOfKinFirstName: "",
    nextOfKinLastName: "",
    nextOfKinAddress: "",
  });
  const [open, setOpen] = useState({ status: false, title: "" });
  const [phone, setPhone] = useState("");
  const [states, setStates] = useState<string[]>([]);
  const [lgas, setLgas] = useState<string[]>([]);
  const { data, isFetching } = useGetMembersQuery({ page, queryTag, search });

  const showDrawer = (title: "add" | "update") => {
    setOpen({ status: true, title });
  };

  const onCloseAddMember = () => {
    setPayload(initialState);
    setPhone("");
    setOpen({ status: false, title: "" });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleUpdateChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUpdatePayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (selectedUser) {
      setUpdatePayload({
        email: selectedUser.email || "",
        firstName: selectedUser.firstName || "",
        lastName: selectedUser.lastName || "",
        state: selectedUser.state || "",
        city: selectedUser.city || "",
        address: selectedUser.address || "",
        dateOfBirth: selectedUser.dateOfBirth || "",
        phoneNumber: selectedUser.phoneNumber || "",
        nextOfKinFirstName: selectedUser.nextOfKinFirstName || "",
        nextOfKinLastName: selectedUser.nextOfKinLastName || "",
        nextOfKinAddress: selectedUser.nextOfKinAddress || "",
      });
    }
  }, [selectedUser]);

  useEffect(() => {
    const getStatesFromApi = async () => {
      try {
        const response = await fetch(
          "https://nga-states-lga.onrender.com/fetch"
        );
        const data = await response.json();
        setStates(data);
      } catch (error) {
        console.error(error);
      }
    };
    getStatesFromApi();
  }, []);

  const getLGAsFromApi = async (state: string) => {
    if (!state) return;
    try {
      const response = await fetch(
        `https://nga-states-lga.onrender.com/?state=${state}`
      );
      if (!response.ok) throw new Error("Failed to fetch LGAs");
      const data = await response.json();
      setLgas(data);
    } catch (error) {
      console.error(error);
      setLgas([]);
      message.error("Failed to fetch LGAs");
    }
  };

  const handleLGAChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedLGA = e.target.value;
    setPayload({ ...payload, city: selectedLGA });
  };

  const handleStateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const state = e.target.value;
    setPayload({ ...payload, state, city: "" });
    getLGAsFromApi(state);
  };

  const { mutateAsync, isLoading: isSubmitting } = useAddMembers();
  const { mutateAsync: updateMemberDetails, isLoading: isUpdating } =
    useUpdateMemberProfile(selectedUser?.id);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutateAsync(
      { ...payload, phoneNumber: phone },
      {
        onSuccess: () => {
          onCloseAddMember();
        },
      }
    );
  };

  const handleUpdate = (e: FormEvent) => {
    e.preventDefault();
    updateMemberDetails(updatePayload, {
      onSuccess: () => {
        onCloseAddMember();
      },
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <span className="truncate">
          {company?.data?.society?.societyName.slice(0, 5)}-{id.slice(-7)}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (dateOfBirth: string) => {
        const date = new Date(dateOfBirth);
        const formattedDate = new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(date);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
  ];

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const paginationConfig = {
    total: data?.data?.meta?.totalDocs || 0,
    current: data?.data?.meta?.page || 1,
    pageSize: data?.data?.meta?.limit || 10,
    onChange: handlePageChange,
  };

  const navigate = useNavigate();

  return (
    <AppLayout title="Members">
      <div className="flex items-center justify-between mb-[20px]">
        <div>
          <h3 className="text-black text-[16px]">
            All Members{" "}
            {data?.data?.meta?.totalDocs > 0 &&
              `(${data?.data?.meta?.totalDocs})`}
          </h3>
          <p className="text-[14px]">
            View and Interact with the Co-operative or Society Members
          </p>
        </div>
        <Link
          to="/member-management/create"
          className="text-green rounded-full p-[10px] border-[1px] border-green text-[13px]"
        >
          Add new Member
        </Link>
      </div>
      <div className="flex justfy-end">
        <select
          className="p-3 rounded-md border-[1px] border-[#00000033]"
          name="tag"
          value={queryTag}
          onChange={(e) => {
            setQueryTag(e?.target?.value);
          }}
        >
          <option value="">Filter</option>
          <option value="all">All Members</option>
          <option value="new">New</option>
          <option value="deleted">Deleted</option>
        </select>
      </div>

      <Table
        rowKey="id"
        columns={columns}
        scroll={{ y: "60vh" }}
        dataSource={data?.data?.docs}
        className="manrope mt-[20px] w-full"
        rowClassName="cursor-pointer"
        pagination={paginationConfig}
        loading={isFetching}
        onRow={(record: any) => ({
          onClick: () => navigate(`/member-management/${record.id}`),
        })}
      />
      {/* ) : (
        <Empty description="No member yet, Proceed to add new members" />
      )} */}
      <Drawer
        width={500}
        title={
          <div className="flex justify-between items-center">
            <span>
              <h3 className="text-[16px]">
                {open?.title === "add" ? "Add Member" : "Update Member"}
              </h3>
              <p className="text-[14px] font-normal">
                {open?.title === "add"
                  ? "Add a new member to the society"
                  : "Update a member of the society"}
              </p>
            </span>
            <button onClick={onCloseAddMember}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-black"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        }
        open={open?.status}
        closable={false}
      >
        {open?.title === "add" && (
          <form onSubmit={handleSubmit} className="p-[20px]">
            <div className="flex gap-3">
              <div className="w-6/12 mb-3">
                <label htmlFor="firstName" className="text-[#14141480]">
                  First Name
                </label>
                <input
                  name="firstName"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="First Name"
                  disabled={isSubmitting}
                  value={payload?.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="w-6/12">
                <label htmlFor="lastName" className="text-[#14141480]">
                  Last Name
                </label>
                <input
                  name="lastName"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="Last Name"
                  disabled={isSubmitting}
                  value={payload?.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-6/12 mb-3">
                <label htmlFor="phoneNumber" className="text-[#14141480]">
                  Phone Number
                </label>
                <PhoneInput
                  defaultCountry="ng"
                  name="phoneNumber"
                  disabled={isSubmitting}
                  className=" mt-2"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
              </div>
              <div className="w-6/12">
                <label htmlFor="email" className="text-[#14141480]">
                  Email Address
                </label>
                <input
                  name="email"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="Email Address"
                  disabled={isSubmitting}
                  value={payload?.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="dateOfBirth" className="text-[#14141480]">
                Date of Birth
              </label>
              <input
                name="dateOfBirth"
                type="date"
                className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                placeholder="Select Date"
                disabled={isSubmitting}
                value={payload?.dateOfBirth}
                onChange={handleChange}
              />
            </div>
            <div className="flex gap-3">
              <div className="w-6/12">
                <label htmlFor="state" className="text-[#14141480]">
                  State
                </label>
                <select
                  name="state"
                  disabled={isSubmitting}
                  value={payload.state}
                  onChange={handleStateChange}
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                >
                  <option value="">Select State</option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-6/12">
                <label htmlFor="lga" className="text-[#14141480]">
                  Local Government Area (LGA)
                </label>
                <select
                  name="lga"
                  disabled={isSubmitting || lgas.length === 0}
                  value={payload.city}
                  onChange={handleLGAChange}
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                >
                  <option value="">Select LGA</option>
                  {lgas.map((lga, index) => (
                    <option key={index} value={lga}>
                      {lga}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-[20px]">
              <label htmlFor="nextOfKinAddress" className="text-[#14141480]">
                Address
              </label>
              <textarea
                name="address"
                className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2 resize-none"
                placeholder="Enter Address"
                value={payload?.address}
                onChange={handleChange}
              />
            </div>
            <div className="mt-[20px] flex justify-end">
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-green text-white px-[35px] py-[10px] rounded-[100px]"
              >
                {isSubmitting ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  "Add Member"
                )}
              </button>
            </div>
          </form>
        )}
        {open?.title === "update" && (
          <form onSubmit={handleUpdate} className="p-[20px]">
            {/* <p className="text-[10px]">{JSON.stringify(selectedUser)}</p> */}
            <div className="flex gap-3">
              <div className="w-6/12 mb-3">
                <label htmlFor="firstName" className="text-[#14141480]">
                  First Name
                </label>
                <input
                  name="firstName"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="First Name"
                  disabled={isUpdating}
                  value={updatePayload.firstName || ""}
                  onChange={handleUpdateChange}
                />
              </div>
              <div className="w-6/12">
                <label htmlFor="lastName" className="text-[#14141480]">
                  Last Name
                </label>
                <input
                  name="lastName"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="Last Name"
                  disabled={isUpdating}
                  value={updatePayload.lastName || ""}
                  onChange={handleUpdateChange}
                />
              </div>
            </div>

            <div className="flex gap-3">
              <div className="w-6/12 mb-3">
                <label htmlFor="phoneNumber" className="text-[#14141480]">
                  Phone Number
                </label>
                <PhoneInput
                  defaultCountry="ng"
                  name="phoneNumber"
                  disabled={isUpdating}
                  className="mt-2"
                  value={updatePayload.phoneNumber || ""}
                  onChange={(phone) =>
                    setUpdatePayload({ ...updatePayload, phoneNumber: phone })
                  }
                />
              </div>
              <div className="w-6/12">
                <label htmlFor="email" className="text-[#14141480]">
                  Email Address
                </label>
                <input
                  name="email"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="Email Address"
                  disabled={isUpdating}
                  value={updatePayload.email || ""}
                  onChange={handleUpdateChange}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-6/12">
                <label htmlFor="state" className="text-[#14141480]">
                  State
                </label>
                <select
                  name="state"
                  disabled={isUpdating}
                  value={updatePayload.state || ""}
                  onChange={handleStateChange}
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                >
                  <option value="">Select State</option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-6/12">
                <label htmlFor="lga" className="text-[#14141480]">
                  Local Government Area (LGA)
                </label>
                <select
                  name="lga"
                  disabled={isUpdating || lgas.length === 0}
                  value={updatePayload.city || ""}
                  onChange={handleLGAChange}
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                >
                  <option value="">Select LGA</option>
                  {lgas.map((lga, index) => (
                    <option key={index} value={lga}>
                      {lga}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-[20px]">
              <label htmlFor="address" className="text-[#14141480]">
                Address
              </label>
              <textarea
                name="address"
                className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2 resize-none"
                placeholder="Enter Address"
                value={updatePayload?.address || ""}
                onChange={handleUpdateChange}
              />
            </div>
            {/* <div className="flex gap-3">
              <div className="w-6/12">
                <label htmlFor="state" className="text-[#14141480]">
                  State
                </label>
                <select
                  name="state"
                  disabled={isSubmitting}
                  value={updatePayload.state || ''}
                  onChange={handleStateChange}
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                >
                  <option value="">Select State</option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-6/12">
                <label htmlFor="lga" className="text-[#14141480]">
                  Local Government Area (LGA)
                </label>
                <select
                  name="lga"
                  disabled={isSubmitting || lgas.length === 0}
                  value={updatePayload.city || ''}
                  onChange={handleLGAChange}
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                >
                  <option value="">Select LGA</option>
                  {lgas.map((lga, index) => (
                    <option key={index} value={lga}>
                      {lga}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-[20px]">
              <label htmlFor="nextOfKinAddress" className="text-[#14141480]">
                Address
              </label>
              <textarea
                name="address"
                className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2 resize-none"
                placeholder="Enter Address"
                value={updatePayload?.nextOfKinAddress || ''}
                onChange={handleChange}
              />
            </div> */}

            <h3 className="font-bold mt-3">Next of Kin Data</h3>
            <hr className="my-[10px]" />
            <div className="flex gap-3">
              <div className="w-6/12 mb-3">
                <label
                  htmlFor="nextOfKinFirstName"
                  className="text-[#14141480]"
                >
                  Next of Kin First Name
                </label>
                <input
                  name="nextOfKinFirstName"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="John"
                  disabled={isUpdating}
                  value={updatePayload.nextOfKinFirstName || ""}
                  onChange={handleUpdateChange}
                />
              </div>
              <div className="w-6/12">
                <label htmlFor="nextOfKinLastName" className="text-[#14141480]">
                  Next of Kin Last Name
                </label>
                <input
                  name="nextOfKinLastName"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="Doe"
                  disabled={isUpdating}
                  value={updatePayload.nextOfKinLastName || ""}
                  onChange={handleUpdateChange}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="nextOfKinAddress" className="text-[#14141480]">
                Next of Kin Address
              </label>
              <textarea
                name="nextOfKinAddress"
                className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2 resize-none"
                placeholder="Enter Address"
                disabled={isUpdating}
                value={updatePayload.nextOfKinAddress || ""}
                onChange={handleUpdateChange}
              />
            </div>
            <div className="mt-[20px] flex justify-end">
              <button
                type="submit"
                disabled={isUpdating}
                className="bg-green text-white px-[35px] py-[10px] rounded-[100px]"
              >
                {isUpdating ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  "Update Member"
                )}
              </button>
            </div>
          </form>
        )}
      </Drawer>
    </AppLayout>
  );
};

export default Members;
