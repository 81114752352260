import React from 'react';
import { Skeleton } from 'antd';

const UserInfoLoader = () => {
  return (
    <div>
      <div className="flex items-center gap-2 mb-3">
        <Skeleton.Avatar active size="small" />
        <Skeleton.Input style={{ width: 150, height: 20 }} active />
      </div>

      <div className="flex items-center gap-2 mb-3">
        <Skeleton.Avatar active size="small" />
        <Skeleton.Input style={{ width: 150, height: 20  }} active />
      </div>

      <div className="flex items-center gap-2 mb-3">
        <Skeleton.Avatar active size="small" />
        <Skeleton.Input style={{ width: 150, height: 20  }} active />
      </div>

      <div className="flex items-center gap-2 mb-3">
        <Skeleton.Avatar active size="small" />
        <Skeleton.Input style={{ width: 150, height: 20  }} active />
      </div>
    </div>
  );
};

export default UserInfoLoader;
