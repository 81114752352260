import { Button, message, Upload, Spin } from "antd";
import React, { useState } from "react";
import { useUploadFile } from "../../../../hooks/api/Misc/useUploadFile";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { useAddDepositEntry } from "../../../../hooks/Entries/useAddDepositEntry";
import { useParams } from "react-router-dom";
import { useWithrawSpecialSavings } from "../../../../hooks/Entries/useWithrawSpecialSavings";

interface Payload {
  tag: "Withdrawal";
  amount: string;
  paymentMethod: string;
  transactionDate: string;
  entryReceipt: string;
  bank: string;
  accountNumber: string;
}

const Withdrawal = ({ setOpen }: { setOpen: any }) => {
  const [payload, setPayload] = useState<Payload>({
    tag: "Withdrawal",
    amount: "",
    paymentMethod: "",
    transactionDate: "",
    entryReceipt: "",
    bank: "",
    accountNumber: "",
  });
  const { id } = useParams();

  const [activeTag, setActiveTag] = useState<string>("Savings (Default)");
  const activeButton = "p-[10px] rounded-md bg-green text-white flex-1";
  const inActiveButton =
    "p-[10px] rounded-md bg-white text-black border-[1px] border-[#00000033] flex-1 text-[14px]";

  const tags = [
    "Special Savings",
    "Monthly Dues",
    "Development",
    "Other Savings",
    "Withdrawal",
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date: string) => {
    const formattedDate = new Date(date).toISOString();
    setPayload((prev) => ({ ...prev, transactionDate: formattedDate }));
  };

  const formatNumberWithCommas = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/,/g, "");
    setPayload((prev) => ({ ...prev, [name]: numericValue }));
  };

  const {
    mutateAsync: uploadFile,
    isLoading: isUploading,
    data,
  } = useUploadFile();
  const { mutateAsync: withdrawFromBalance, isLoading: isLoadingEntries } =
    useWithrawSpecialSavings(id);

  const handleAddDeposit = () => {
    withdrawFromBalance(payload, {
      onSuccess: () => {
        message.success("Deposit entry added successfully!");
        setOpen({ title: "", status: false, subTitle: "" });
      },
      onError: (error) => {
        message.error(`Error adding deposit entry: ${error.message}`);
      },
    });
  };

  const handleFileChange = async (info: any) => {
    const { originFileObj } = info.file;
    if (originFileObj) {
      try {
        const uploadResponse = await uploadFile(originFileObj);
        // message.success("Upload successful");
        setPayload((prev) => ({ ...prev, entryReceipt: uploadResponse?.data }));
      } catch (error) {
        console.error("Upload failed:", error);
        message.error("Upload failed, please try again.");
      }
    }
  };

  return (
    <div>
      <h3 className="text-black mb-2">Details</h3>
      <hr />
      <p className="text-[#14141480] my-3">Upload Receipt</p>
      {!data ? (
        <Upload onChange={handleFileChange} listType="picture">
          <Button type="dashed" icon={<UploadOutlined />}>
            Upload Receipt
          </Button>
        </Upload>
      ) : (
        <img
          src={data?.data}
          className="h-[100px] w-[100px] rounded-full"
          alt="Uploaded receipt"
        />
      )}

      <div>
        <p className="text-[#14141480] mt-2">Enter Amount</p>
        <input
          type="text"
          name="amount"
          className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
          placeholder="Enter amount"
          value={formatNumberWithCommas(payload.amount)}
          onChange={handleInputChange}
          autoComplete="off"
        />
      </div>
      <hr />
      <div className="flex gap-2 my-[20px]">
        <div className="w-6/12">
          <label className="text-[#14141480] mt-2" htmlFor="paymentMethod">
            Payment Method
          </label>
          <select
            id="paymentMethod"
            name="paymentMethod"
            className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
            onChange={handleChange}
            value={payload.paymentMethod}
          >
            <option value="" disabled>
              Choose Option
            </option>
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
          </select>
        </div>
        <div className="w-6/12">
          <label className="text-[#14141480] mt-2" htmlFor="transactionDate">
            Transaction Date
          </label>
          <input
            id="transactionDate"
            name="transactionDate"
            type="date"
            className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
            onChange={(e) => handleDateChange(e.target.value)}
          />
        </div>
      </div>
      <div className="flex gap-2 mb-[20px]">
        <div className="w-6/12">
          <label className="text-[#14141480] mt-2" htmlFor="accountNumber">
            Account Number
          </label>
          <input
            id="accountNumber"
            name="accountNumber"
            type="text"
            className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
            placeholder="Enter account number"
            value={payload.accountNumber}
            onChange={handleChange}
          />
        </div>
        <div className="w-6/12">
          <label className="text-[#14141480] mt-2" htmlFor="bank">
            Bank
          </label>
          <input
            id="bank"
            name="bank"
            type="text"
            className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
            placeholder="Enter bank name"
            value={payload.bank}
            onChange={handleChange}
          />
        </div>
      </div>
      <button
        onClick={handleAddDeposit}
        disabled={
          !payload.accountNumber ||
          !payload.amount ||
          !payload.bank ||
          !payload.paymentMethod ||
          !payload.entryReceipt ||
          !payload.tag ||
          !payload.transactionDate
        }
        className={`p-3 font-bold text-white bg-green rounded-full w-full ${
          !payload.accountNumber ||
          !payload.amount ||
          !payload.bank ||
          !payload.paymentMethod ||
          !payload.entryReceipt ||
          !payload.tag ||
          !payload?.transactionDate
            ? "opacity-50 cursor-not-allowed"
            : ""
        }`}
      >
        {isLoadingEntries ? (
          <Spin
            className="text-white"
            size={"large"}
            indicator={<LoadingOutlined spin />}
          />
        ) : (
          "Submit"
        )}
      </button>
    </div>
  );
};

export default Withdrawal;
